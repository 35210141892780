import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, RouterLinkActive } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TopNavComponent } from 'layouts/top-nav/top-nav.component';
import { SidebarNavComponent } from 'layouts/sidebar-nav/sidebar-nav.component';
import { FooterComponent } from 'layouts/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
      RouterLink,
      RouterOutlet,
      RouterLinkActive,
      TopNavComponent,
      MatSidenavModule,
      SidebarNavComponent,
      FooterComponent,
  ],
  templateUrl: './root.component.html',
  styleUrl: './root.component.scss'
})
export class RootComponent {

}

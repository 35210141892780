import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Observable, map, ReplaySubject, combineLatest, mergeMap, } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { IframeComponent } from 'components/iframe/iframe.component';
import { IAdvertiser } from 'model';
import { AdvertiserService } from 'services';
import { environment, } from 'util';

export enum GamePlanSecondLook {
    GamePlan,
    SecondLook,
}

type RouteData = {
    isPastCampaign: boolean;
    pageType: GamePlanSecondLook;
}

@Component({
    selector: 'app-game-plan-second-look',
    standalone: true,
    imports: [
        CommonModule,
        BreadcrumbComponent,
        IframeComponent,
    ],
    templateUrl: './game-plan-second-look.component.html',
    styleUrl: './game-plan-second-look.component.scss'
})
export class GamePlanSecondLookComponent {
    private apiUrl = environment.apiUrl;

    private urlSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
    protected url$: Observable<string> = this.urlSubject.asObservable();

    private advertiserIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    private eventIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    private categoryIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);

    protected breadcrumbs$: Observable<Breadcrumb[]> = combineLatest([
        this.advertiserIdSubject.pipe(
            mergeMap((advertiserId: number) => this.advertiserService.getAdvertiser(advertiserId))
        ),
        this.route.data
    ]).pipe(
        map(([advertiser, data]: [IAdvertiser, Data]) => {
            const pageType = (data as RouteData).pageType;
            const indexPage = (data as RouteData).isPastCampaign
                ? {label: 'Past Campaigns', url: `/advertiser/${advertiser.aid}/past-campaigns`}
                : {label: 'Upcoming Campaigns', url: `/advertiser/${advertiser.aid}/upcoming-campaign`}
            return [
                {label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns`},
                indexPage,
                {label: pageType === GamePlanSecondLook.GamePlan ? 'Game Plan': 'Second Look', url: ''},
            ];
        })
    );

    @Input()
    set advertiserId(advertiserId: number) {
        this.advertiserIdSubject.next(advertiserId);
    }

    @Input()
    set eventId(eventId: number) {
        this.eventIdSubject.next(eventId);
    }

    @Input()
    set categoryId(categoryId: number) {
        this.categoryIdSubject.next(categoryId);
    }

    constructor(
        private advertiserService: AdvertiserService,
        private route: ActivatedRoute
    ) {
        this.url$ = combineLatest([
            this.advertiserIdSubject,
            this.eventIdSubject,
            this.categoryIdSubject,
            this.route.data
        ]).pipe(
            map(([advertiserId, eventId, categoryId, data]: [number, number, number, Data]) => {
                const pageType = (data as RouteData).pageType;
                if (pageType === GamePlanSecondLook.GamePlan) {
                    return `${this.apiUrl}/gamePlanStats/view?aid=${advertiserId}&eventId=${eventId}&categoryId=${categoryId}&api=true`;
                } else {
                    return `${this.apiUrl}/secondLookStats/view?aid=${advertiserId}&eventId=${eventId}&categoryId=${categoryId}&api=true`;
                }
            })
        );
    }
}

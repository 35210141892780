@if (!breadcrumbs) {
<div class="loader">
    <ngx-skeleton-loader count="1" appearance="line" [theme]="{width: '500px'}" />
</div>
}
<nav aria-label="breadcrumb">
    <ul class="breadcrumb">
        @for (breadcrumb of breadcrumbs; track breadcrumb; let last = $last) {
        <li class="breadcrumb-item" [class]="{ 'active': last }">
            @if (!last) {
            <a class="breadcrumb-label" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a><mat-icon fontIcon="chevron_right" />
            }
            @else {
            <span class="breadcrumb-label">{{ breadcrumb.label }}</span>
            }
        </li>
        }
    </ul>
</nav>

import { Record } from 'immutable';

import { ITradeShow, TradeShow, Advertiser, } from './index';

export enum Brand {
    PW = 1,
    HCP = 3,
    OEM = 4,
    OPX = 5,
    DW = 6,
    PFW = 8,
    PMG = 9,
    PACKEXPO = 12,
    Mundo = 13,
    EBA = 14,
    CPG = 15
}

export const BrandSelectOptions: { id: any, name: string }[] = [{id: -1, name: ''}].concat(Object.keys(Brand)
    .filter((key: any) => !isNaN(key))
    .reduce((acc: { id: number, name: string }[], key: any) => {
        acc.push({id: key, name: Brand[key]});
        return acc;
    }, []));

export enum CampaignType {
    Email = 1,
    Web = 2,
    App = 3,
    TradeShow = 4,
    TargetedBranding = 5,
    VideoExposure = 6,
    Facebook = 7,
    LinkedIn = 8,
    Print = 9,
}

export enum CampaignSubtype {
    Playbook = 1,
    Newsletter = 2,
    Custom = 6,
    Webinar = 18,
    PMGRegForm = 19,
    EditorialWebinar = 61,
    SupplierWebinar = 62,
    CollaborativeWebinar = 63,
}

export const CampaignTypeSelectOptions: { id: any, name: string }[] = [{id: -1, name: ''}].concat(Object.keys(CampaignType)
    .filter((key: any) => !isNaN(key))
    .reduce((acc: { id: number, name: string }[], key: any) => {
        acc.push({id: key, name: CampaignType[key]});
        return acc;
    }, []));

export interface ICampaign {
    aid: number;
    assignedTo: number | null;
    brandId: number;
    cid: number;
    createdBy: number | null;
    date: number;
    expireDate: number | null;
    name: string;
    subtype: number | null;
    templateId: number;
    type: number;
    approved: boolean;
    materialsDue: number | null;
    leadCount?: number;
    tradeShowId?: number;
    projectUrl?: string;
    tradeShow?: ITradeShow | null;

    uniqueClicks?: number;
    uniqueClickThroughRate?: number;
    impressions?: number;
    reportingCompleteDate?: number;
    distribution?: number;

    advertiser?: Advertiser | null;
}
const CampaignRecord = Record<ICampaign>({
    aid: 0,
    assignedTo: null,
    brandId: 0,
    cid: 0,
    createdBy: null,
    date: 0,
    expireDate: null,
    name: '',
    subtype: null,
    templateId: 0,
    type: 0,
    approved: false,
    materialsDue: null,
    leadCount: 0,
    tradeShowId: 0,
    projectUrl: '',
    uniqueClicks: 0,
    uniqueClickThroughRate: 0,
    impressions: 0,
    reportingCompleteDate: 0,
    distribution: 0,
    tradeShow: null,
    advertiser: null,
});
export class Campaign extends CampaignRecord implements ICampaign {
    public static WEBINAR_SUBTYPES: (CampaignSubtype)[] = [
        CampaignSubtype.Webinar, CampaignSubtype.EditorialWebinar, CampaignSubtype.SupplierWebinar,
        CampaignSubtype.CollaborativeWebinar
    ];

    constructor(props: ICampaign) {
        const originalProps = props;
        // make deep copy of props to avoid modifying original object
        props = JSON.parse(JSON.stringify(props));

        if (props.tradeShow) {
            if (originalProps.tradeShow instanceof TradeShow) {
                props.tradeShow = originalProps.tradeShow;
            } else {
                props.tradeShow = new TradeShow(props.tradeShow);
            }
        }

        if (props.advertiser) {
            if (originalProps.advertiser instanceof Advertiser) {
                props.advertiser = originalProps.advertiser;
            } else {
                props.advertiser = new Advertiser(props.advertiser);
            }
        }

        super(props);
    }

    private _brand!: keyof typeof Brand;
    get brand(): keyof typeof Brand {
        if (!this._brand) {
            this._brand = Brand[this.brandId] as keyof typeof Brand;
        }
        return this._brand;
    }

    private _campaignType!: keyof typeof CampaignType;
    get campaignType(): keyof typeof CampaignType {
        if (!this._campaignType) {
            this._campaignType = CampaignType[this.type] as keyof typeof CampaignType;
        }
        return this._campaignType;
    }

    private _tradeShowFullName!: string;
    get tradeShowFullName(): string {
        if (!this._tradeShowFullName) {
            this._tradeShowFullName = this.tradeShow?.fullName || '';
        }
        return this._tradeShowFullName;
    }

    get reportingCompleteDateMillis(): number | undefined {
        if (!this.reportingCompleteDate) {
            return undefined;
        }
        return this.reportingCompleteDate * 1000;
    }

    get materialsDueMillis(): number | undefined {
        if (!this.materialsDue) {
            return undefined;
        }
        return this.materialsDue * 1000
    }

    #advertiserName!: string;
    get advertiserName(): string {
        if (!this.#advertiserName) {
            this.#advertiserName = this.advertiser?.name || '';
        }
        return this.#advertiserName;
    }

    #upcomingCampaignsLink!: string;
    get upcomingCampaignsLink(): string {
        if (!this.#upcomingCampaignsLink) {
            this.#upcomingCampaignsLink = `/advertiser/${this.aid}/upcoming-campaigns/campaign/${this.cid}`;
        }
        return this.#upcomingCampaignsLink;
    }

    #approvedPendingStatus!: string;
    get approvedPendingStatus(): string {
        if (!this.#approvedPendingStatus) {
            this.#approvedPendingStatus = this.approved ? 'Approved' : 'Pending';
        }
        return this.#approvedPendingStatus;
    }

    isWebinar(): boolean {
        return this.type === CampaignType.Web && Campaign.WEBINAR_SUBTYPES.includes(this.subtype as CampaignSubtype);
    }

    getFormattedDate(): string {
        const date = new Date(this.date * 1000);

        // return formatted in 'MM-DD-YYYY' format
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }

    areMaterialsPastDue(): boolean {
        if (!this.materialsDue) {
            return false;
        }

        const now = new Date();
        return this.materialsDue * 1000 < now.getTime();
    }

    isPrintCampaign(): boolean {
        return this.type === CampaignType.Print;
    }

    isWebCampaign(): boolean {
        return this.type === CampaignType.Web;
    }

    isCampaignViewable(): boolean {
        if (this.isPrintCampaign()) {
            return false;
        }

        return true;
    }

    hasLeads(): boolean {
        if (!this.leadCount) return false;
        return this.leadCount > 0;
    }

    hasImpressions(): boolean {
        if (!this.impressions) return false;
        return this.impressions > 0;
    }

    hasReportingCompleteDate(): boolean {
        return !!this.reportingCompleteDate;
    }
}

export class CampaignList extends Array<Campaign> {
    constructor(...campaigns: ICampaign[] | Campaign[]) {
        const mapped = campaigns.map((campaign: ICampaign | Campaign) => {
            if (campaign instanceof Campaign) {
                return campaign;
            }

            if (typeof campaign !== 'object' || campaign === null) {
                return campaign;
            }

            return new Campaign(campaign);
        });
        super(...mapped);
    }

    filterByCampaignType(type: CampaignType): CampaignList {
        return new CampaignList(...this.filter((campaign: Campaign) => campaign.type === type));
    }

    filterByCampaignTypeAndSubtype(type: CampaignType, ...subtype: CampaignSubtype[]): CampaignList {
        return new CampaignList(...this.filter((campaign: Campaign) => campaign.type === type && subtype.includes(campaign.subtype as CampaignSubtype)));
    }
}
export interface CampaignList {
    filter(predicate: (value: Campaign, index: number, array: Campaign[]) => boolean): CampaignList;
    slice(start?: number, end?: number): CampaignList;
}

@if (dataSource.loading$ | async) {
<div class="loader">
    <ngx-skeleton-loader count="5" appearance="line" [theme]="{height: '64px'}" />
</div>
}
<div class="table-container mat-elevation-z0" [style.display]="(_dataToDisplay && _dataToDisplay.length || !hideIfEmpty) ? 'block' : 'none'">
    <div class="table-header-section">
        @if (header) {
        <h1 class="table-header-title">{{ header }}</h1>
        }
        <div class="table-actions-container">
            <mat-paginator [length]="dataSource.dataLength$ | async"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions">
            </mat-paginator>
            <div class="filter-container">
                @for (column of columnConfigs; track column) {
                @if (column.filter && column.filter.options) {
                <mat-form-field #formField>
                    <mat-label #formLabel>FILTER BY {{ column.filter.label | uppercase }}</mat-label>
                    <mat-select [formControl]="filterFormControls[column.property]">
                        @for (option of column.filter.options; track option) {
                        <mat-option [value]="option.id">
                            <span style="width: 100%;" [innerHTML]="option.name | safe : 'html'"></span>
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                }
                }
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource">
        <ng-content></ng-content>
    </table>
</div>

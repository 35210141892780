<div class="content">
    <div class="equal-column gray-background">
        <div class="slide-to-center">
            <div class="login-area">
                <div class="login-logo-container">
                    <img class="login-logo-left login-logo-leadworks-logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180">
                    <img class="login-logo-right" ngSrc="assets/pmg_logo_2.png" alt="Leadworks" width="150" height="72.6"> <!-- Real size: width="500" height="242" -->
                </div>
                <div class="login-card">
                    <h2>Create or Reset Password</h2>
                    <div class="login-explainer-text">
                        Enter the email address linked to your account update your password.<br>
                        <br>
                        If you do not receive the email in your inbox, please check your spam/junk and other folders.
                    </div>
                    @if (showMessage) {
                        <div class="login-message" [attr.data-message-type]="messageType">{{message}}</div>
                    }
                    <div class="field" data-field-type="label-email-and-button">
                        <label for="account-email-address">Email Address</label>
                        <input type="email" id="account-email-address" placeholder="Email Address" [(ngModel)]="emailAddressValue">
                        <button mat-flat-button (click)="handleForgotPasswordButtonClick(emailAddressValue)">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="copyright-notice">
        {{copyrightNotice}}
    </div>
</footer>

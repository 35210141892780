<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Reports & Tools</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if (!(advertiser$ | async)) {
    <div class="loader">
        <ngx-skeleton-loader count="8" appearance="line" [theme]="{height: '64px'}"></ngx-skeleton-loader>
    </div>
}

<app-card-list>
    @if (advertiser$ | async; as advertiser) {
        @if (advertiser.baseIdEncrypted || advertiser.mundoBaseIdEncrypted) {
            <app-card-item>
                <app-card-item-title-text>
                    Content Engagement Reports
                </app-card-item-title-text>
                <app-card-item-title-description>
                    Measure cross-channel content performance.
                </app-card-item-title-description>
                @if (advertiser.baseIdEncrypted) {
                <app-card-item-link>
                    <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                       [href]="'https://engagement.pmmimediagroup.com/' + advertiser.baseIdEncrypted" target="_blank">
                        VIEW REPORTS <mat-icon>open_in_new</mat-icon>
                    </a>
                </app-card-item-link>
                }
                @if (advertiser.mundoBaseIdEncrypted) {
                <app-card-item-link>
                    <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                       [href]="'https://engagement.mundoexpopack.com/' + advertiser.mundoBaseIdEncrypted" target="_blank">
                        VIEW MUNDO REPORTS <mat-icon>open_in_new</mat-icon>
                    </a>
                </app-card-item-link>
                }
            </app-card-item>
        }

        <app-card-item>
            <app-card-item-title-text>
                Website Tracking (Scout) & Converge
            </app-card-item-title-text>
            <app-card-item-title-description>
                Connect end user engagement with your advertising content and visits to your website.<br/>
                View cross-channel engagement from multiple contacts across an organization.
            </app-card-item-title-description>
            <app-card-item-link>
                <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;"
                   [href]="convergeUrl + '/advertiser/' + advertiser.aid" target="_blank">
                    VIEW REPORTS <mat-icon>open_in_new</mat-icon>
                </a>
            </app-card-item-link>
        </app-card-item>

        @if (advertiser.mediaDiscoveryQuotes?.length) {
            <app-card-item>
                <app-card-item-title-text>
                    Media Discovery Options
                </app-card-item-title-text>
                <app-card-item-title-description>
                    Placeholder description.
                </app-card-item-title-description>
                <app-card-item-link>
                    <a mat-flat-button color="primary" class="pmg-primary-dark" style="width: 100%;" routerLink="media-discovery-tool">
                        VIEW OPTIONS
                    </a>
                </app-card-item-link>
            </app-card-item>
        }
    }
</app-card-list>

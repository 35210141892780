import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { PEI_ICON, PXE_ICON, PEM_ICON, PELV_ICON, PES_ICON, EPG_ICON, } from 'util';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry.addSvgIconLiteral(
            'pei',
            this.domSanitizer.bypassSecurityTrustHtml(PEI_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pxe',
            this.domSanitizer.bypassSecurityTrustHtml(PXE_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pem',
            this.domSanitizer.bypassSecurityTrustHtml(PEM_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pelv',
            this.domSanitizer.bypassSecurityTrustHtml(PELV_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'pes',
            this.domSanitizer.bypassSecurityTrustHtml(PES_ICON)
        );
        this.matIconRegistry.addSvgIconLiteral(
            'epg',
            this.domSanitizer.bypassSecurityTrustHtml(EPG_ICON)
        );
    }
}

@if (loading$ | async) {
    <ngx-skeleton-loader count="5" appearance="line" [theme]="{height: '64px'}" />
}

<iframe #campaignIframe
        [src]="src | safe : 'resourceUrl'"
        style="border: none;"
        [style.width]="width"
        [style.display]="(loading$ | async) ? 'none' : 'block'"
></iframe>

<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">My Advertisers Upcoming Campaigns</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if ((dataToDisplay$ | async)?.length === 0) {
<div class="no-data">
    You currently do not have any upcoming campaigns.
</div>
}

<app-wrapper-table matSort #sort="matSort" [tableSort]="sort"
                   [dataToDisplay]="dataToDisplay$ | async"
                   [hideIfEmpty]="true"
                   [columnConfigs]="[
                       { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', } },
                       { property: 'type', filter: { label: 'Campaign Type', options: campaignTypes, type: 'select', } }
                   ]"
>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaign Date </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : Campaign).getFormattedDate() }}
        </td>
    </ng-container>

    <ng-container matColumnDef="advertiserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'upcoming-campaigns']">
                {{ (element | asType : Campaign).advertiserName }}
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
            @if ((element | asType: Campaign).upcomingCampaignsLink) {
                <a [routerLink]="(element | asType: Campaign).upcomingCampaignsLink">
                    {{ (element | asType : Campaign).name }}
                </a>
            } @else {
                {{ (element | asType : Campaign).name }}
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : Campaign).brand }}
        </td>
    </ng-container>

    <ng-container matColumnDef="campaignType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let element">
            <strong>{{ (element | asType : Campaign).campaignType }}</strong>
        </td>
    </ng-container>

    <ng-container matColumnDef="materialsDue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Materials Due </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : Campaign).materialsDueMillis | date: 'MM-dd-yyyy' }}
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell
            *matCellDef="let element"
            [style]="(element | asType : Campaign).approvedPendingStatus === 'Approved' ? { color: '#87CB92' } : { color: '#CE2126' }"
        >
            <strong>{{ (element | asType : Campaign).approvedPendingStatus }}</strong>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['date', 'advertiserName', 'name', 'brand', 'campaignType', 'materialsDue', 'status']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['date', 'advertiserName', 'name', 'brand', 'campaignType', 'materialsDue', 'status']"></tr>
</app-wrapper-table>

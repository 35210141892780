import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-password',
    standalone: true,
    imports: [
        NgOptimizedImage,
        MatButton
    ],
    templateUrl: './password.component.html',
    styleUrl: './password.component.scss'
})
export class PasswordComponent {
    protected _copyrightNotice!: string;
    protected showMessage: boolean = false;
    protected messageType: string = 'default';
    protected message: string = '';

    constructor(
        private router: Router
    ) {
        // Do nothing for now.
    }

    get copyrightNotice(): string {
        if (this._copyrightNotice) {
            return this._copyrightNotice;
        }

        let year: number = new Date().getFullYear();
        this._copyrightNotice = `Copyright © ${year} PMMI Media Group. All rights reserved.`;

        return this._copyrightNotice;
    }

    handleNewPasswordClick(): void {
        void this.router.navigate(['/forgot-password']);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, } from '@angular/common/http';
import { Observable, map, catchError, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { IUser, User } from 'model/user';

import { environment } from 'util';

type UserLoginResponse = {
    success: boolean;
    message: string;
    isLoggedIn: boolean;
}

type UserLogoutResponse = {
    success: boolean;
    message: string;
    isLoggedIn: boolean;
}

type UserForgotPasswordResponse = {
    success: boolean;
    message: string;
    isSent: boolean;
}

type VerifyUserResetPasswordTokenResponse = {
    success: boolean;
    message: string;
    isValid: boolean;
    userId: number;
    username: string;
    userEmail: string;
}

type ResetPasswordResponse = {
    success: boolean;
    message: string;
    isValid: boolean;
    userId: number;
    username: string;
    userEmail: string;
    didResetPassword: boolean;
}

type ViewAsUserResponse = {
    isSuccess: string;
    problem: string;
}

type GetIsViewingAsUserResponse = {
    isViewingAsUser: string;
}

export enum UserLoginStatus {
    Success = 200,
    AlreadyLoggedIn = 202,
    SentMfaEmail = 207,
    Failed = 401,
    Conflict = 409,
    Exception = 503,
}

export enum UserLogOutStatus {
    Success = 200,
    AlreadyLoggedOut = 202,
    Conflict = 409,
    Failed = 422,
    Exception = 503,
}

export enum UserForgotPasswordStatus {
    Success = 200,
    Conflict = 409,
    Exception = 503,
}

export enum VerifyUserResetPasswordTokenStatus {
    Success = 200,
    Conflict = 409,
    Failed = 400,
}

export enum ResetPasswordStatus {
    Success = 200,
    Conflict = 409,
    Failed = 400,
}

export enum StartViewingAsUserStatus {
    Success = 200,
    Failed = 409,
}

export enum StopViewingAsUserStatus {
    Success = 200,
    Failed = 503,
}

export enum GetIsViewingAsUserStatus {
    Yes = 200,
    No = 207,
    Failed = 400,
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private apiUrl = environment.apiUrl;
    private loginEndpoint = `${this.apiUrl}/_/api/session/login`;
    private loginByTokenEndpoint = `${this.apiUrl}/_/api/session/login-by-token`;
    private logoutEndpoint = `${this.apiUrl}/_/api/session/logout`;
    private getIsLoggedInEndpoint = `${this.apiUrl}/_/api/session/get-is-logged-in`;
    private forgotPasswordEndpoint = `${this.apiUrl}/_/api/account/forgot-password`;
    private verifyResetPasswordTokenEndpoint = `${this.apiUrl}/_/api/account/verify-reset-password-token`;
    private resetPasswordEndpoint = `${this.apiUrl}/_/api/account/reset-password`;
    private startViewingAsUserEndpoint = `${this.apiUrl}/_/api/view-as-user/start-viewing-as-user`;
    private stopViewingAsUserEndpoint = `${this.apiUrl}/_/api/view-as-user/stop-viewing-as-user`;
    private getIsViewingAsUserEndpoint = `${this.apiUrl}/_/api/view-as-user/get-is-viewing-as-user`;

    private loggedInUser$: Observable<User> = this.http.get<IUser>(`${this.apiUrl}/_/api/user`).pipe(
        map((user: IUser) => new User(user)),
        shareReplay(1)  // Cache the user
    );

    constructor(
        private http: HttpClient
    ) {
        // Do nothing for now
    }

    getLoggedInUser(): Observable<User> {
        return this.loggedInUser$;
    }

    requestLogin(username: string, password: string, rememberMe: boolean): Observable<HttpResponse<UserLoginResponse>> {
        return this.http.post<UserLoginResponse>(
            this.loginEndpoint,
            {
                username: username,
                password: password,
                rememberMe: rememberMe
            },
            {observe: 'response'}
        );
    }

    requestLoginByToken(token: string, rememberMe: boolean): Observable<HttpResponse<UserLoginResponse>> {
        return this.http.post<UserLoginResponse>(
            this.loginByTokenEndpoint,
            {
                token: token,
                rememberMe: rememberMe
            },
            {observe: 'response'}
        );
    }

    requestLogOut(): Observable<HttpResponse<UserLogoutResponse>> {
        return this.http.post<UserLogoutResponse>(
            this.logoutEndpoint,
            {},
            {observe: 'response'}
        );
    }

    requestSendForgotPasswordEmail(emailAddress: string): Observable<HttpResponse<UserForgotPasswordResponse>> {
        return this.http.post<UserForgotPasswordResponse>(
            this.forgotPasswordEndpoint,
            {
                emailAddress: emailAddress
            },
            {observe: 'response'}
        );
    }

    requestVerifyResetPasswordToken(reset: string): Observable<HttpResponse<VerifyUserResetPasswordTokenResponse>> {
        return this.http.post<VerifyUserResetPasswordTokenResponse>(
            this.verifyResetPasswordTokenEndpoint,
            {
                reset: reset
            },
            {observe: 'response'}
        );
    }

    requestResetPassword(reset: string, newPassword: string, confirmNewPassword: string): Observable<HttpResponse<ResetPasswordResponse>> {
        return this.http.post<ResetPasswordResponse>(
            this.resetPasswordEndpoint,
            {
                reset: reset,
                newPassword: newPassword,
                confirmNewPassword: confirmNewPassword
            },
            {observe: 'response'}
        );
    }

    isLoggedInUserAnAdmin(): Observable<boolean> {
        return this.loggedInUser$.pipe(
            map((user: User) => user.hasRole('admin'))
        );
    }

    requestStartViewingAsUser(userId: number): Observable<HttpResponse<ViewAsUserResponse>> {
        return this.http.post<ViewAsUserResponse>(
            this.startViewingAsUserEndpoint,
            {
                userId: userId,
            },
            {observe: 'response'}
        );
    }

    requestStopViewingAsUser(): Observable<HttpResponse<ViewAsUserResponse>> {
        return this.http.post<ViewAsUserResponse>(
            this.stopViewingAsUserEndpoint,
            {},
            {observe: 'response'}
        );
    }

    requestGetIsViewingAsUser(): Observable<HttpResponse<GetIsViewingAsUserResponse>> {
        return this.http.post<GetIsViewingAsUserResponse>(
            this.getIsViewingAsUserEndpoint,
            {},
            {observe: 'response'}
        );
    }
}

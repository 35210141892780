import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import {
    CardItemComponent,
    CardItemLinkComponent,
    CardItemTitleDescriptionComponent,
    CardItemTitleTextComponent,
    CardItemIconComponent,
} from './card-item.component';
import { CardListComponent } from './card-list.component';

@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        MatCardModule,
        CardListComponent,
        CardItemComponent,
        CardItemTitleTextComponent,
        CardItemTitleDescriptionComponent,
        CardItemLinkComponent,
        CardItemIconComponent,
    ],
    exports: [
        CardListComponent,
        CardItemComponent,
        CardItemTitleTextComponent,
        CardItemTitleDescriptionComponent,
        CardItemLinkComponent,
        CardItemIconComponent,
    ],
})
export class CardListModule {
}

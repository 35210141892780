import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse, } from '@angular/common/http';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { catchError, of, } from 'rxjs';

import { UserService, UserForgotPasswordStatus, } from 'services';

@Component({
    selector: 'app-forgot-password',
    standalone: true,
    imports: [
        NgOptimizedImage,
        FormsModule,
        MatButton
    ],
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
    protected _copyrightNotice!: string;
    protected showMessage: boolean = false;
    protected messageType: string = 'default';
    protected message: string = '';
    protected emailAddressValue: string = '';

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        // Do nothing for now.
    }

    get copyrightNotice(): string {
        if (this._copyrightNotice) {
            return this._copyrightNotice;
        }

        let year: number = new Date().getFullYear();
        this._copyrightNotice = `Copyright © ${year} PMMI Media Group. All rights reserved.`;

        return this._copyrightNotice;
    }

    handleForgotPasswordButtonClick(givenEmailAddress: string): void {
        // Check if email address is empty
        if (!givenEmailAddress || givenEmailAddress.length === 0) {
            this.showLoginMessage('warning', 'An email address was not provided.');
            return;
        }

        this.showLoginMessage('info', 'Sending a forgot-password link to your email address...');

        // Send the forgot-password email
        this.userService.requestSendForgotPasswordEmail(givenEmailAddress)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.message || '';

                    // Handle the failure states
                    switch (responseStatus) {
                        case UserForgotPasswordStatus.Conflict:
                            this.showLoginMessage('error', errorMessage);
                            break;
                        case UserForgotPasswordStatus.Exception:
                            this.showLoginMessage('warning', errorMessage);
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.message || '';

                // Handle the success states
                switch (response.status) {
                    case UserForgotPasswordStatus.Success:
                        this.showLoginMessage('success', message);
                        break;
                    default:
                        break;
                }
            });
    }

    protected showLoginMessage(messageType: string, message: string): void {
        this.message = message;
        this.messageType = messageType;
        this.showMessage = true;
    }
}

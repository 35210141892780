import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { Observable, map } from 'rxjs';

import { User, Advertiser, IAdvertiser, ITradeShow, TradeShow } from 'model';
import { UserService, AdvertiserService, CampaignService, } from 'services';
import {
    PAST_CAMPAIGNS, REPORTS_AND_TOOLS, UPCOMING_EVENT, SIDEBAR_CHEVRON_RIGHT, MY_ADVERTISERS, EXHIBITOR_PORTAL,
    CONTACTS, HISTORY,
} from './svg-icons';
import { environment } from 'util';

@Component({
    selector: 'sidebar-nav',
    standalone: true,
    imports: [
        MatListModule,
        MatButtonModule,
        NgIf,
        CommonModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
    ],
    templateUrl: './sidebar-nav.component.html',
    styleUrl: './sidebar-nav.component.scss'
})
export class SidebarNavComponent implements OnInit {
    protected user$: Observable<User> = this.userService.getLoggedInUser();

    protected advertiser$!: Observable<Advertiser>;

    protected tradeShows$: Observable<TradeShow[]> = this.campaignService.getExhibitorPortalTradeShows().pipe(
        map((tradeShows: ITradeShow[]) => tradeShows.map((tradeShow: ITradeShow) => new TradeShow(tradeShow))),
    );

    protected advertiserId: number | undefined;

    protected convergeUrl = environment.convergeUrl;

    protected apiUrl = environment.apiUrl;

    constructor(
        private userService: UserService,
        private advertiserService: AdvertiserService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private campaignService: CampaignService,
    ) {
        this.matIconRegistry.addSvgIconLiteral('event-upcoming', this.domSanitizer.bypassSecurityTrustHtml(UPCOMING_EVENT));
        this.matIconRegistry.addSvgIconLiteral('event-past', this.domSanitizer.bypassSecurityTrustHtml(PAST_CAMPAIGNS));
        this.matIconRegistry.addSvgIconLiteral('reports-and-tools', this.domSanitizer.bypassSecurityTrustHtml(REPORTS_AND_TOOLS));
        this.matIconRegistry.addSvgIconLiteral('sidebar-chevron-right', this.domSanitizer.bypassSecurityTrustHtml(SIDEBAR_CHEVRON_RIGHT));
        this.matIconRegistry.addSvgIconLiteral('my-advertisers', this.domSanitizer.bypassSecurityTrustHtml(MY_ADVERTISERS));
        this.matIconRegistry.addSvgIconLiteral('exhibitor-portal', this.domSanitizer.bypassSecurityTrustHtml(EXHIBITOR_PORTAL));
        this.matIconRegistry.addSvgIconLiteral('contacts', this.domSanitizer.bypassSecurityTrustHtml(CONTACTS));
        this.matIconRegistry.addSvgIconLiteral('history', this.domSanitizer.bypassSecurityTrustHtml(HISTORY));
    }

    ngOnInit(): void {
        this.advertiserId = Number(this.route.snapshot.paramMap.get('advertiserId')) || undefined;

        if (this.advertiserId) {
            this.advertiser$ = this.advertiserService.getAdvertiser(this.advertiserId).pipe(
                map((advertiser: IAdvertiser) => new Advertiser(advertiser))
            );
        }
    }
}

import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { UserService, UserLoginStatus, } from 'services';
import { catchError } from 'rxjs';
import { HttpErrorResponse, } from '@angular/common/http';

@Component({
  selector: 'app-use-login-token',
  standalone: true,
    imports: [
        FormsModule,
        MatButton,
        NgOptimizedImage
    ],
  templateUrl: './use-login-token.component.html',
  styleUrl: './use-login-token.component.scss'
})
export class UseLoginTokenComponent {
    protected _copyrightNotice!: string;
    protected showMessage: boolean = false;
    protected messageType: string = 'default';
    protected message: string = '';
    protected didCheckToken: boolean = false;

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        if(!this.didCheckToken){
            // Only run once, not every time the constructor gets re-run
            this.didCheckToken = true;

            // Check the token
            this.checkToken();
        }
    }

    get copyrightNotice() : string {
        if (this._copyrightNotice) {
            return this._copyrightNotice;
        }

        let year: number = new Date().getFullYear();
        this._copyrightNotice = `Copyright © ${year} PMMI Media Group. All rights reserved.`;

        return this._copyrightNotice;
    }

    protected showLoginMessage(messageType: string, message: string): void {
        this.message = message;
        this.messageType = messageType;
        this.showMessage = true;
    }

    protected getUrlVariables() : { [key: string]: any }
    {
        // Get URL info
        let urlVariables : any[] = [];
        let url : string = window.location.href;
        let urlQuery : string = url.slice(url.indexOf('?') + 1);
        let queryArgs : string[] = urlQuery.split('&');

        // Loop through the URL GET arguments
        let queryArg : { [key: string]: any };
        for (let i = 0; i < queryArgs.length; i++)
        {
            queryArg = queryArgs[i].split('=');
            urlVariables[queryArg[0]] = queryArg[1];
        }

        // Return array of GET variables
        return urlVariables;
    }

    protected checkToken() : void {
        // Show message that we're checking the login token
        this.showLoginMessage('info', 'Checking login token...');

        // Check the login token exists
        let queryArgs : { [key: string]: any } = this.getUrlVariables();
        console.log(queryArgs);

        // Get token from URL
        let hasToken : boolean = queryArgs.hasOwnProperty('token');
        let token : string = (hasToken) ? queryArgs['token']  : '';

        // Check if the token exists
        if(!hasToken){
            this.showLoginMessage('error', 'A token was not provided.');
            return;
        }

        // Get the Remember-Me from URL
        let hasRemember : boolean = queryArgs.hasOwnProperty('remember');
        let remember : string = (hasRemember) ? queryArgs['remember']  : '';
        let shouldRemember : boolean = remember === 'yes';

        // Check if the Remember-Me exists
        if(!hasRemember){
            this.showLoginMessage('error', 'The Remember-Me status was not provided.');
            return;
        }

        // Login by token
        this.userService.requestLoginByToken(token, shouldRemember)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.message || '';

                    // Handle the failure states
                    switch (responseStatus) {
                        case UserLoginStatus.Failed:
                        this.showLoginMessage('error', errorMessage);
                        break;
                    case UserLoginStatus.Conflict:
                        this.showLoginMessage('error', errorMessage);
                        break;
                    case UserLoginStatus.Exception:
                        this.showLoginMessage('warning', errorMessage);
                        break;
                    default:
                        break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.message || '';

                // Handle the success states
                switch (response.status) {
                    case UserLoginStatus.Success:
                        this.showLoginMessage('success', message);
                        void this.router.navigate(['/']);
                        break;
                    case UserLoginStatus.AlreadyLoggedIn:
                        this.showLoginMessage('info', message);
                        void this.router.navigate(['/']);
                        break;
                    default:
                        break;
                }
            });
    }
}

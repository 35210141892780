<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">My Advertisers</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if ((dataToDisplay$ | async)?.length === 0) {
    <div class="no-data">
        You currently are not associated with any advertisers.
    </div>
}

<app-wrapper-table matSort #sort="matSort" [tableSort]="sort"
                   [dataToDisplay]="dataToDisplay$ | async"
                   [hideIfEmpty]="true"
>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser Name </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="'/advertiser/' + (element | asType : Advertiser).aid + '/upcoming-campaigns'">
                {{ (element | asType : Advertiser).name }}
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="brands">
        <th mat-header-cell *matHeaderCellDef> Brands </th>
        <td mat-cell *matCellDef="let element">
            <strong>{{ (element | asType : Advertiser).brands | brands }}</strong>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['name', 'brands']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name', 'brands']"></tr>
</app-wrapper-table>

import { Pipe, PipeTransform } from '@angular/core';

import { CampaignType } from '../model/campaign';

@Pipe({
    name: 'campaignType',
    standalone: true
})
export class CampaignTypePipe implements PipeTransform {
    transform(value: number): string {
        return CampaignType[value];
    }
}

import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AsyncPipe } from "@angular/common";

export interface Breadcrumb {
    label: string;
    url: string;
}

@Component({
    selector: 'app-breadcrumb',
    standalone: true,
    imports: [
        RouterLink,
        NgxSkeletonLoaderModule,
        AsyncPipe,
        MatIconModule,
    ],
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
    @Input() breadcrumbs: Breadcrumb[] | null = [];
}

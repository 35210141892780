<div class="content">
    <div class="equal-column gray-background">
        <div class="slide-to-center">
            <div class="login-area">
                <div class="login-logo-container">
                    <img class="login-logo-left login-logo-leadworks-logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180">
                    <img class="login-logo-right" ngSrc="assets/pmg_logo_2.png" alt="Leadworks" width="150" height="72.6"> <!-- Real size: width="500" height="242" -->
                </div>
                <div class="login-card">
                    @if (showMessage) {
                        <div class="login-message" [attr.data-message-type]="messageType">{{message}}</div>
                    }
                    <h2>First time user?</h2>

                    <div class="field" data-field-type="explainer-text-and-button">
                        <div class="explainer-text">
                            Click here to confirm your account:
                        </div>
                        <button mat-flat-button (click)="handleNewPasswordClick()">Confirm Account</button>
                    </div>
                    <br>
                    <h2>Forgot password?</h2>
                    <div class="field" data-field-type="explainer-text-and-button">
                        <div class="explainer-text">
                            Click here to reset your password:
                        </div>
                        <button mat-flat-button (click)="handleNewPasswordClick()">Reset Password</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="copyright-notice">
        {{copyrightNotice}}
    </div>
</footer>

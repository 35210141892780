<mat-list>

    @if ((user$ | async)?.isSalesRep) {
        <div mat-subheader class="accent">SALESPERSON DASHBOARD</div>
        <mat-list-item>
            <div matListItemTitle>
                <a mat-button
                   color="warning"
                   [routerLink]="['/user', (user$ | async)?.id, 'my-advertisers']"
                   routerLinkActive="active-link"
                   [routerLinkActiveOptions]="{ exact: true }"
                >
                    <mat-icon svgIcon="my-advertisers" aria-hidden="false"></mat-icon> My Advertisers
                </a>
            </div>
        </mat-list-item>
<!--        <mat-list-item>-->
<!--            <div matListItemTitle>-->
<!--                <a mat-button-->
<!--                   color="warning"-->
<!--                   [routerLink]="['/user', (user$ | async)?.id, 'my-advertisers', 'upcoming-campaigns']"-->
<!--                   routerLinkActive="active-link"-->
<!--                >-->
<!--                    <mat-icon svgIcon="event-upcoming" aria-hidden="false"></mat-icon> My Advertisers Upcoming Campaigns-->
<!--                </a>-->
<!--            </div>-->
<!--        </mat-list-item>-->
<!--        <mat-list-item>-->
<!--            <div matListItemTitle>-->
<!--                <a mat-button-->
<!--                   color="warning"-->
<!--                   [routerLink]="['/user', (user$ | async)?.id, 'my-advertisers', 'past-campaigns']"-->
<!--                   routerLinkActive="active-link"-->
<!--                >-->
<!--                    <mat-icon svgIcon="event-past" aria-hidden="false"></mat-icon> My Advertisers Past Campaigns-->
<!--                </a>-->
<!--            </div>-->
<!--        </mat-list-item>-->
        <mat-list-item>
            <div matListItemTitle>
                <a mat-button
                   color="warning"
                   [href]="apiUrl + '/_/campaigns/history/e-blasts'"
                   target="_blank"
                   routerLinkActive="active-link"
                >
                    <mat-icon svgIcon="history" aria-hidden="false"></mat-icon> History
                </a>
            </div>
        </mat-list-item>
    }

    @if (advertiser$ | async; as advertiser) {
        <div mat-subheader class="accent">{{ (advertiser$ | async)?.name | uppercase }}</div>
        @if (!(user$ | async)?.isSalesRep && (user$ | async)?.isMultiAdvertiserUser) {
            <mat-list-item>
                <div matListItemTitle>
                    <a mat-button color="warning" [routerLink]="['/user', (user$ | async)?.id, 'my-advertisers']" routerLinkActive="active-link">
                        <mat-icon svgIcon="my-advertisers" aria-hidden="false"></mat-icon> My Advertisers
                    </a>
                </div>
            </mat-list-item>
        }
        <mat-list-item>
            <div matListItemTitle>
                <a mat-button color="warning" routerLink="upcoming-campaigns" routerLinkActive="active-link">
                    <mat-icon svgIcon="event-upcoming" aria-hidden="false"></mat-icon> Upcoming Campaigns
                </a>
            </div>
        </mat-list-item>
        <mat-list-item>
            <div matListItemTitle>
                <a mat-button color="warning" routerLink="past-campaigns" routerLinkActive="active-link">
                    <mat-icon svgIcon="event-past" aria-hidden="false"></mat-icon> Past Campaigns
                </a>
            </div>
        </mat-list-item>
        <mat-list-item class="mdc-list-item__with-inner-items" routerLinkActive="mdc-list-item__with-inner-items__open">
            <div matListItemTitle>
                <a mat-button class="top-level-menu-link" color="warning" routerLink="reports-and-tools" routerLinkActive="active-link">
                    <mat-icon svgIcon="reports-and-tools" aria-hidden="false" />
                    Reports &amp; Tools
                    <mat-icon iconPositionEnd svgIcon="sidebar-chevron-right" aria-hidden="false" class="sidebar-chevron-right" />
                </a>
                <mat-list-item class="mdc-list-item__with-inner-items__inner-list" routerLinkActive="opened">
                    <mat-list class="sidebar-nav-inner-list">
                        @if (advertiser.baseIdEncrypted) {
                        <mat-list-item>
                            <a mat-button color="warning" class="sidebar-nav-inner-list-button"
                               [href]="'https://engagement.pmmimediagroup.com/' + (advertiser$ | async)?.baseIdEncrypted"
                               target="_blank"
                            >
                                Content Engagement Reports <mat-icon iconPositionEnd>open_in_new</mat-icon>
                            </a>
                        </mat-list-item>
                        }
                        <mat-list-item>
                            <a mat-button color="warning" class="sidebar-nav-inner-list-button"
                               [href]="convergeUrl + '/advertiser/' + advertiserId" target="_blank"
                            >
                                Website Tracking (Scout) <mat-icon iconPositionEnd>open_in_new</mat-icon>
                            </a>
                        </mat-list-item>
                        @if (advertiser.mediaDiscoveryQuotes?.length) {
                        <mat-list-item>
                            <a mat-button color="warning" class="sidebar-nav-inner-list-button"
                               routerLink="reports-and-tools/media-discovery-tool"
                               routerLinkActive="active-inner-link"
                            >
                                Media Discovery Tool
                            </a>
                        </mat-list-item>
                        }
                    </mat-list>
                </mat-list-item>
            </div>
        </mat-list-item>

        <div mat-subheader class="accent">PMMI TRADE SHOWS</div>
        <mat-list-item class="mdc-list-item__with-inner-items" routerLinkActive="mdc-list-item__with-inner-items__open">
            <div matListItemTitle>
                <a mat-button class="top-level-menu-link" color="warning" routerLink="exhibitor-portal" routerLinkActive="active-link">
                    <mat-icon svgIcon="exhibitor-portal" aria-hidden="false" />
                    Exhibitor Resource Center
                    <mat-icon iconPositionEnd svgIcon="sidebar-chevron-right" aria-hidden="false" class="sidebar-chevron-right" />
                </a>
                <mat-list-item class="mdc-list-item__with-inner-items__inner-list" routerLinkActive="opened">
                    <mat-list class="sidebar-nav-inner-list">
                        @for (tradeShow of tradeShows$ | async; track tradeShow.id) {
                        <mat-list-item>
                            <a mat-button color="warning" class="sidebar-nav-inner-list-button"
                               [href]="tradeShow.exhibitorPortalUrl" target="_blank"
                            >
                                {{ tradeShow.fullNameMinusYear }} <mat-icon iconPositionEnd>open_in_new</mat-icon>
                            </a>
                        </mat-list-item>
                        }
                    </mat-list>
                </mat-list-item>
            </div>
        </mat-list-item>

        <div mat-subheader class="accent">YOUR CONTACTS</div>
        <mat-list-item>
            <div matListItemTitle>
                <a mat-button color="warning" routerLink="contacts-directory" routerLinkActive="active-link">
                    <mat-icon svgIcon="contacts" aria-hidden="false"></mat-icon> Contacts Directory
                </a>
            </div>
        </mat-list-item>
    }
</mat-list>

<div class="content">
    <div class="equal-column gray-background">
        <div class="slide-to-center">
            <div class="login-area">
                <div class="login-logo-container">
                    <img class="login-logo-left login-logo-leadworks-logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180">
                    <img class="login-logo-right" ngSrc="assets/pmg_logo_2.png" alt="Leadworks" width="150" height="72.6"> <!-- Real size: width="500" height="242" -->
                </div>
                <div class="login-card">
                    <h2>Logging in to your account</h2>
                    <div class="login-explainer-text">Verifying your login token...</div>
                    @if (showMessage) {
                        <div class="login-message" [attr.data-message-type]="messageType">{{message}}</div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="copyright-notice">
        {{copyrightNotice}}
    </div>
</footer>

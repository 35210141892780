import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpErrorResponse, } from '@angular/common/http';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { catchError, of, } from 'rxjs';

import { UserService, UserLoginStatus, } from 'services';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        FormsModule,
        MatButton,
        NgOptimizedImage
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    protected _copyrightNotice!: string;
    protected usernameValue: string = '';
    protected passwordValue: string = '';
    protected rememberMeValue: boolean = false;
    protected showMessage: boolean = false;
    protected messageType: string = 'default';
    protected message: string = '';

    get copyrightNotice() {
        if (this._copyrightNotice) {
            return this._copyrightNotice;
        }

        let year: number = new Date().getFullYear();
        this._copyrightNotice = `Copyright © ${year} PMMI Media Group. All rights reserved.`;

        return this._copyrightNotice;
    }

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        // Do nothing for now.
    }

    handleLoginClick(givenUsername: string, givenPassword: string, givenRememberMe: boolean): void {
        // Login
        this.userService.requestLogin(givenUsername, givenPassword, givenRememberMe)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.message || '';

                    // Handle the failure states
                    switch (responseStatus) {
                        case UserLoginStatus.Failed:
                        this.showLoginMessage('error', errorMessage);
                        break;
                    case UserLoginStatus.Conflict:
                        this.showLoginMessage('error', errorMessage);
                        break;
                    case UserLoginStatus.Exception:
                        this.showLoginMessage('warning', errorMessage);
                        break;
                    default:
                        break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.message || '';

                // Handle the success states
                switch (response.status) {
                    case UserLoginStatus.Success:
                        this.showLoginMessage('success', message);
                        //void this.router.navigate(['/']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/'; // Go to landing page on a new page load
                        break;
                    case UserLoginStatus.AlreadyLoggedIn:
                        this.showLoginMessage('info', message);
                        //void this.router.navigate(['/']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/'; // Go to landing page on a new page load
                        break;
                    case UserLoginStatus.SentMfaEmail:
                        this.showLoginMessage('success', message);
                        break;
                    default:
                        break;
                }
            });
    }

    protected showLoginMessage(messageType: string, message: string): void {
        this.message = message;
        this.messageType = messageType;
        this.showMessage = true;
    }
}

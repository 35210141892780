import { Pipe, PipeTransform } from '@angular/core';

import { Brand } from 'model/campaign';

@Pipe({
    name: 'brand',
    standalone: true
})
export class BrandPipe implements PipeTransform {
    transform(value: number): string {
        return Brand[value];
    }
}

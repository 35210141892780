import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule, } from '@angular/material/table';
import { RouterLink } from '@angular/router';

import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, } from 'components/wrapper-table';
import { Advertiser, IAdvertiser, MediaDiscoveryQuote } from 'model';
import { map, Observable } from 'rxjs';
import { AdvertiserService, } from 'services';
import { AsTypePipe } from 'util';

@Component({
    selector: 'app-media-discovery-tool',
    standalone: true,
    imports: [
        BreadcrumbComponent,
        CommonModule,
        MatDividerModule,
        MatTableModule,
        MatSortModule,
        RouterLink,
        WrapperTableComponent,
        AsTypePipe,
    ],
    templateUrl: './media-discovery-tool.component.html',
    styleUrl: './media-discovery-tool.component.scss'
})
export class MediaDiscoveryToolComponent {
    protected breadcrumbs$!: Observable<Breadcrumb[]>;

    protected advertiser$!: Observable<Advertiser>;

    protected dataToDisplay$!: Observable<MediaDiscoveryQuote[]>;

    protected _advertiserId!: number;

    @Input()
    set advertiserId(advertiserId: number) {
        this._advertiserId = advertiserId;
        this.advertiser$ = this.advertiserService.getAdvertiser(advertiserId).pipe(
            map((advertiser: IAdvertiser) => new Advertiser(advertiser))
        );
        this.breadcrumbs$ = this.advertiser$.pipe(
            map((advertiser: Advertiser) => [
                { label: `${advertiser.name}`, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Reports & Tools', url: `/advertiser/${advertiser.aid}/reports-and-tools`, },
                { label: 'Media Discovery Tool', url: '', },
            ])
        );
        this.dataToDisplay$ = this.advertiser$.pipe(
            map(advertiser => advertiser.mediaDiscoveryQuotes as MediaDiscoveryQuote[])
        );
    }
    get advertiserId(): number { return this._advertiserId; }

    readonly MediaDiscoveryQuote = MediaDiscoveryQuote;

    constructor(
        private advertiserService: AdvertiserService,
    ) {}
}

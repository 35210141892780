<top-nav></top-nav>
<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" opened="true" class="main-sidenav">
        <sidebar-nav></sidebar-nav>
    </mat-sidenav>
    <mat-sidenav-content>
        <div id="content">
            <router-outlet />
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>

<div class="content">
    <div class="equal-column gray-background">
        <div class="slide-to-center">
            <div class="login-area">
                <div class="login-logo-container">
                    <img class="login-logo-left login-logo-leadworks-logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180">
                    <img class="login-logo-right" ngSrc="assets/pmg_logo_2.png" alt="Leadworks" width="150" height="72.6"> <!-- Real size: width="500" height="242" -->
                </div>
                <div class="login-card">

                    @if (!isResetTokenValid) {
                        <h2>Reset Password</h2>
                        <div class="login-explainer-text">Verifying your token...</div>
                    }
                    @if (isResetTokenValid) {
                        <h2>Reset Password for {{username}}<br>({{userEmail}})</h2>
                    }
                    @if (showMessage) {
                        <div class="login-message" [attr.data-message-type]="messageType">{{message}}</div>
                    }
                    @if (isResetTokenValid && !didResetPassword) {
                        <div>
                            Requires 12 characters or more: <i><pre class="allowed-chars-pre"><code>A-Z, a-z, 0-9,?!&#64;&#35;$&percnt;^&*&#45;_+=</code></pre></i>
                        </div>

                        <div class="field" data-field-type="login-password">
                            <div class="slide-to-left">
                                <label for="password">New Password</label>
                            </div>
                            <div class="input-stretcher">
                                <input type="password" id="password" [(ngModel)]="newPasswordValue">
                            </div>
                        </div>

                        <div class="field" data-field-type="login-password">
                            <div class="slide-to-left">
                                <label for="password-confirm">Confirm New Password</label>
                            </div>
                            <div class="input-stretcher">
                                <input type="password" id="password-confirm" [(ngModel)]="confirmNewPasswordValue">
                            </div>
                        </div>

                        <div class="field" data-field-type="submit">
                            <button mat-flat-button (click)="handleSubmitResetClick(newPasswordValue, confirmNewPasswordValue)">Reset Password</button>
                        </div>
                    }
                    @if (isResetTokenValid && didResetPassword) {
                        <div class="field" data-field-type="submit">
                            <div class="slide-to-right">
                                <button mat-flat-button (click)="handleGoToLoginClick()">Return to Login</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="copyright-notice">
        {{copyrightNotice}}
    </div>
</footer>

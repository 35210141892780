<mat-toolbar>
    <a class="home-link" routerLink="/home">
        <img class="logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180" priority>
    </a>
    <div class="top-right-menu-row">
        <button mat-button [matMenuTriggerFor]="menu" *ngIf="user$ | async">
            <span class="top-right-menu-button-content">
                {{(user$ | async)?.username}}
                <mat-icon svgIcon="dropdown-chevron-down" aria-hidden="false"></mat-icon>
            </span>
        </button>
        <mat-menu #menu="matMenu">
            @if (isAdmin$ | async) {
            <button mat-menu-item class="top-right-menu-dropdown-option" (click)='openViewAsUserDialog()'>View as User</button>
            }
            @if (isViewingAsUser) {
            <button mat-menu-item class="top-right-menu-dropdown-option" (click)='returnToAdmin()'>Return to Admin</button>
            }
            <button mat-menu-item class="top-right-menu-dropdown-option" (click)='logout()'>Log Out</button>
        </mat-menu>
    </div>
</mat-toolbar>

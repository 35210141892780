import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, map, } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { CardListModule } from 'components/card-list/card-list.module';
import { AdvertiserService } from 'services';
import { IAdvertiser, Advertiser } from 'model';
import { environment } from 'util';

@Component({
    selector: 'app-reports-and-tools',
    standalone: true,
    imports: [
        BreadcrumbComponent,
        CommonModule,
        MatButtonModule,
        CardListModule,
        MatDividerModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
        RouterLink,
    ],
    templateUrl: './reports-and-tools.component.html',
    styleUrl: './reports-and-tools.component.scss'
})
export class ReportsAndToolsComponent {
    protected breadcrumbs$!: Observable<Breadcrumb[]>;

    protected advertiser$!: Observable<Advertiser>;

    protected convergeUrl: string = environment.convergeUrl;

    @Input()
    set advertiserId(advertiserId: number) {
        this.advertiser$ = this.advertiserService.getAdvertiser(advertiserId).pipe(
            map((advertiser: IAdvertiser) => new Advertiser(advertiser))
        );
        this.breadcrumbs$ = this.advertiser$.pipe(
            map((advertiser: Advertiser) => [
                { label: `${advertiser.name}`, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Reports & Tools', url: '', },
            ])
        );
    }

    constructor(
        private advertiserService: AdvertiserService,
    ) {
    }
}

import { Component, ContentChild, AfterContentInit, } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-card-item-icon',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <ng-content></ng-content>
    `,
    styles: [`
        :host { display: flex; }
    `]
})
export class CardItemIconComponent {}

@Component({
    selector: 'app-card-item-title-text',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <ng-content></ng-content>
    `,
    styles: []
})
export class CardItemTitleTextComponent {}

@Component({
    selector: 'app-card-item-title-description',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <ng-content></ng-content>
    `,
    styles: []
})
export class CardItemTitleDescriptionComponent {}

@Component({
    selector: 'app-card-item-link',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <ng-content></ng-content>
    `,
    styles: [`
        :host:not(:only-child):not(:last-child) { margin-bottom: 8px; }
    `]
})
export class CardItemLinkComponent {}

@Component({
    selector: 'app-card-item',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <mat-card-content>
            @if (iconAvailable) {
            <div class="icon-section">
                <ng-content select="app-card-item-icon"></ng-content>
            </div>
            }
            <div class="title-section">
                <h2><ng-content select="app-card-item-title-text"></ng-content></h2>
                @if (descriptionAvailable) {
                <p><ng-content select="app-card-item-title-description"></ng-content></p>
                }
            </div>
            <div class="link-section">
                <ng-container>
                    <ng-content select="app-card-item-link"></ng-content>
                </ng-container>
            </div>
        </mat-card-content>
    `,
    styleUrl: './card-item.component.scss'
})
export class CardItemComponent implements AfterContentInit {
    @ContentChild(CardItemIconComponent) icon!: CardItemIconComponent;
    @ContentChild(CardItemTitleDescriptionComponent) description!: CardItemTitleDescriptionComponent;

    protected iconAvailable: boolean = false;
    protected descriptionAvailable: boolean = false;

    ngAfterContentInit() {
        this.iconAvailable = !!this.icon;
        this.descriptionAvailable = !!this.description;
    }
}

import { Record } from 'immutable';

export interface ITradeShow {
    id: number;
    name: string;
    fullName: string;
    year: number;
    active: boolean;
    showType: string;
    secondLookDate: number | null;
    gamePlanDate: number | null;
    exhibitorPortalUrl: string | null;
}
const tradeShowDefaultValues: ITradeShow = {
    id: 0,
    name: '',
    fullName: '',
    year: 0,
    active: false,
    showType: '',
    secondLookDate: 0,
    gamePlanDate: 0,
    exhibitorPortalUrl: null
};
const TradeShowRecord = Record<ITradeShow>({
    ...tradeShowDefaultValues
});
export class TradeShow extends TradeShowRecord implements ITradeShow {
    constructor(props: ITradeShow) {
        super(props);
    }

    private _fullNameMinusYear!: string;
    get fullNameMinusYear(): string {
        if (this._fullNameMinusYear) {
            return this._fullNameMinusYear;
        }

        this._fullNameMinusYear = this.fullName.replace(/\s\d{4}$/, '');
        return this._fullNameMinusYear;
    }
}

export interface ITradeShowReport {
    aid: number;
    eventId: number;
    categoryName?: string;
    categoryId?: number;
    recipients?: number;
    name: string;
    publishDate?: number;
    tradeShow: ITradeShow;
}
const TradeShowReportRecord = Record<ITradeShowReport>({
    aid: 0,
    eventId: 0,
    name: '',
    categoryName: '',
    categoryId: 0,
    recipients: 0,
    publishDate: 0,
    tradeShow: new TradeShow(tradeShowDefaultValues)
});
export class TradeShowReport extends TradeShowReportRecord implements ITradeShowReport {
    constructor(props: ITradeShowReport) {
        if (props.tradeShow) {
            props.tradeShow = new TradeShow(props.tradeShow);
        }
        super(props);
    }

    get gamePlanPastLink(): string {
        return `/advertiser/${this.aid}/past-campaigns/game-plan/event/${this.eventId}/category/${this.categoryId}`;
    }

    get secondLookPastLink(): string {
        return `/advertiser/${this.aid}/past-campaigns/second-look/event/${this.eventId}/category/${this.categoryId}`;
    }

    get facilitiesAndInfrastructurePastLink(): string {
        return `/advertiser/${this.aid}/past-campaigns/facilities-and-infrastructure/${this.eventId}/`;
    }

    get tradeShowFullName(): string {
        return this.tradeShow?.fullName || '';
    }

    private _formatedPublishDate!: string;
    get formatedPublishDate(): string {
        if (!this.publishDate) {
            return '';
        }

        if (this._formatedPublishDate) {
            return this._formatedPublishDate;
        }

        const date = new Date(this.publishDate * 1000);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        this._formatedPublishDate = `${month}-${year}`;
        return this._formatedPublishDate;
    }
}

export class TradeShowReportList extends Array<TradeShowReport> {
    constructor(...tradeShows: ITradeShowReport[]) {
        const mapped = tradeShows.map((tradeShow: ITradeShowReport | TradeShowReport) => {
            if (tradeShow instanceof TradeShowReport) {
                return tradeShow;
            }

            if (typeof tradeShow !== 'object' || tradeShow === null) {
                return tradeShow;
            }

            return new TradeShowReport(tradeShow);
        });
        super(...mapped);
    }
}
export interface TradeShowReportList {
    filter(predicate: (value: TradeShowReport, index: number, array: TradeShowReport[]) => boolean): TradeShowReportList;
}

import { Pipe, PipeTransform } from '@angular/core';

import { Brand } from 'model/campaign';

@Pipe({
    name: 'brands',
    standalone: true
})
export class BrandsPipe implements PipeTransform {
    transform(value: number[]): string {
        return value.map(brand => Brand[brand]).join(', ');
    }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule, } from '@angular/material/table';

import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, } from 'components/wrapper-table';
import {
    Advertiser,
    BrandSelectOptions,
    Campaign,
    CampaignList,
    CampaignSubtype,
    CampaignType,
    CampaignTypeSelectOptions,
    IAdvertiser,
    ICampaign,
} from 'model';
import { map, Observable, ReplaySubject, Subscription } from 'rxjs';
import { AdvertiserService, CampaignService, } from 'services';
import { AsTypePipe, environment } from 'util';

@Component({
    selector: 'app-upcoming-campaigns',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        BreadcrumbComponent,
        WrapperTableComponent,
        AsTypePipe,
        MatTableModule,
        MatSortModule,
        RouterLink,
        MatIconModule,
    ],
    templateUrl: './upcoming-campaigns.component.html',
    styleUrl: './upcoming-campaigns.component.scss',
    providers: []
})
export class UpcomingCampaignsComponent implements OnDestroy {
    protected breadcrumbs$!: Observable<Breadcrumb[]>;

    protected subscriptions : Subscription[] = [];

    protected advertiser$!: Observable<IAdvertiser>;
    protected advertiser: Advertiser | null = null;

    private dataToDisplaySubject: ReplaySubject<CampaignList> = new ReplaySubject<CampaignList>(1);
    dataToDisplay$: Observable<CampaignList> = this.dataToDisplaySubject.asObservable();

    campaignLinkFormatter = (campaign: Campaign) => {
        if (campaign.isWebCampaign() && campaign.hasLeads()) {
            return `/advertiser/${campaign.aid}/upcoming-campaigns/campaign/${campaign.cid}`;
        }
        return null;
    }
    actionFormatter = (campaign: Campaign) => {
        if (campaign.type === CampaignType.Email) {
            return campaign.approved ? 'APPROVED' : 'SUBMIT MATERIALS';
        }
        if (campaign.type === CampaignType.Print) {
            if (campaign.approved) {
                return 'APPROVED';
            }
            else {
                return campaign.projectUrl ? 'SUBMIT MATERIALS' : 'Email your CSM';
            }
        }
        if ([CampaignSubtype.Webinar, CampaignSubtype.CollaborativeWebinar, CampaignSubtype.SupplierWebinar].includes(campaign.subtype as number)) {
            return 'SUBMIT MATERIALS';
        }
        if (campaign.subtype === CampaignSubtype.EditorialWebinar) {
            return 'Email Events';
        }
        return 'Email your CSM';
    };
    actionLinkFormatter = (campaign: Campaign) => {
        if (campaign.type === CampaignType.Email) {
            return campaign.approved
                ? `${environment.apiUrl}/_/campaigns/${campaign.cid}/render-email?showDefaultAds=false`
                : `/advertiser/${campaign.aid}/upcoming-campaigns/campaign/${campaign.cid}/edit`;
        }

        if (campaign.type === CampaignType.Print && campaign.projectUrl) {
            return campaign.projectUrl;
        }

        if ([CampaignSubtype.Webinar, CampaignSubtype.CollaborativeWebinar, CampaignSubtype.SupplierWebinar].includes(campaign.subtype as number)) {
            return 'https://app.smartsheet.com/b/form/61ed659ea33b499ebdad78e442d9f9c7';
        }

        if (campaign.subtype === CampaignSubtype.EditorialWebinar) {
            return `mailto:events@pmmimediagroup.com?subject=Submit Materials - ${campaign.name} - ${campaign.getFormattedDate()}`;
        }

        const csmEmail: string | undefined = this.advertiser?.csmEmail;
        return csmEmail ? `mailto:${csmEmail}?subject=Submit Materials - ${campaign.name} - ${campaign.getFormattedDate()}` : '';
    };
    alertStyleFormatter = (campaign: Campaign, property?: string): { [key: string]: any } => {
        if (!property) {
            return {};
        }
        if (!campaign.approved) {
            if (['action', 'materialsDue', 'date'].includes(property) && campaign.areMaterialsPastDue()) {
                return { color: '#FF0000', fontWeight: 'bold' };
            }
            if (['action'].includes(property)) {
                return { color: '#1A2186' };
            }
        } else {
            if (['action'].includes(property)) {
                return { color: '#32C4C4' };
            }
        }

        return {};
    };
    alertClassFormatter = (campaign: Campaign): string[] => {
        if (!campaign.approved && campaign.areMaterialsPastDue()) {
            return ['alert'];
        }
        return [];
    };

    brands: { id: any, name: string }[] = BrandSelectOptions;
    campaignTypes: { id: any, name: string }[] = CampaignTypeSelectOptions;

    @Input()
    set advertiserId(advertiserId: number) {
        const upcomingCampaignsSub = this.campaignService
            .getUpcomingCampaigns(advertiserId)
            .subscribe((campaigns: ICampaign[]) => {
                this.dataToDisplaySubject.next(new CampaignList(...campaigns));
            });
        this.subscriptions.push(upcomingCampaignsSub);

        this.advertiser$ = this.advertiserService.getAdvertiser(advertiserId);
        this.breadcrumbs$ = this.advertiser$.pipe(
            map((advertiser: IAdvertiser) => {
                return [
                    { label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                    { label: 'Upcoming Campaigns', url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                ];
            })
        );
        const advertiserSub = this.advertiser$
            .subscribe((advertiser: IAdvertiser) => {
                this.advertiser = new Advertiser(advertiser);
            });
        this.subscriptions.push(advertiserSub);
    }

    protected readonly Campaign = Campaign;

    constructor(
        private campaignService: CampaignService,
        private advertiserService: AdvertiserService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    protected isLinkInternal(link: string): boolean {
        return link.startsWith('/');
    }

    protected isMailtoLink(link: string): boolean {
        return link.startsWith('mailto:');
    }
}

import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule, } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, map, Subscription } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { CardListModule } from 'components/card-list/card-list.module';
import { AdvertiserService, CampaignService } from 'services';
import { IAdvertiser, Advertiser, ITradeShow, TradeShow } from 'model';

@Component({
    selector: 'app-exhibitor-portal',
    standalone: true,
    imports: [
        BreadcrumbComponent,
        CommonModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        NgxSkeletonLoaderModule,
        CardListModule,
    ],
    templateUrl: './exhibitor-portal.component.html',
    styleUrl: './exhibitor-portal.component.scss'
})
export class ExhibitorPortalComponent implements OnDestroy {
    protected breadcrumbs$!: Observable<Breadcrumb[]>;
    protected advertiser$!: Observable<Advertiser>;
    protected subscriptions : Subscription[] = [];

    protected tradeShows$: Observable<TradeShow[]> = this.campaignService.getExhibitorPortalTradeShows().pipe(
        map((tradeShows: ITradeShow[]) => tradeShows.map((tradeShow: ITradeShow) => new TradeShow(tradeShow))),
    );

    @Input()
    set advertiserId(advertiserId: number) {
        this.advertiser$ = this.advertiserService.getAdvertiser(advertiserId).pipe(
            map((advertiser: IAdvertiser) => new Advertiser(advertiser))
        );
        this.breadcrumbs$ = this.advertiser$.pipe(
            map((advertiser: Advertiser) => [
                { label: `${advertiser.name}`, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Exhibitor Resource Center', url: '', },
            ])
        );
    }

    constructor(
        private advertiserService: AdvertiserService,
        private campaignService: CampaignService,
    ) {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}

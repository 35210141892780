<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Past Campaigns</h1>

<mat-divider class="page-title-divider"></mat-divider>

<mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="handleIndexChange($event)" (selectedTabChange)="handleTabChange()" dynamicHeight class="tall-tabs">
    <mat-tab label="EMAIL" [labelClass]="(emailCampaigns$ | async )?.length ? 'loaded' : 'loading'">
        @if ((emailCampaigns$ | async )?.length === 0) {
        <div class="no-data">
            You are currently not running any Email campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sort="matSort" [tableSort]="sort" [dataToDisplay]="emailCampaigns$ | async"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="leadCount">
                <th mat-header-cell *matHeaderCellDef> Leads </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).leadCount }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).getFormattedDate() }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="emailColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: emailColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="SOCIAL MEDIA" [labelClass]="(facebookCampaigns$ | async )?.length || (linkedInCampaigns$ | async)?.length ? 'loaded' : 'loading'">
        @if ((facebookCampaigns$ | async )?.length === 0 && (linkedInCampaigns$ | async)?.length === 0) {
        <div class="no-data">
            You are currently not running any social media campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortFacebook="matSort" [tableSort]="sortFacebook" [dataToDisplay]="facebookCampaigns$ | async"
                           [header]="'Facebook'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="uniqueClicks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Clicks </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).uniqueClicks | number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="uniqueClickThroughRate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CTR/Play % </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).uniqueClickThroughRate | percent }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reportingCompleteDateMillis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Reporting </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).reportingCompleteDateMillis | date: 'MM-dd-yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="socialMediaColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: socialMediaColumns"></tr>
        </app-wrapper-table>
        <app-wrapper-table matSort #sortLinkedIn="matSort" [tableSort]="sortLinkedIn" [dataToDisplay]="linkedInCampaigns$ | async"
                           [header]="'LinkedIn'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="uniqueClicks">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Unique Clicks </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).uniqueClicks | number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="uniqueClickThroughRate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> CTR/Play % </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).uniqueClickThroughRate | percent }}
                </td>
            </ng-container>

            <ng-container matColumnDef="reportingCompleteDateMillis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Reporting </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).reportingCompleteDateMillis | date: 'MM-dd-yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="socialMediaColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: socialMediaColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="WEB" [labelClass]="(targetedBrandingCampaigns$ | async )?.length || (videoExposureCampaigns$ | async)?.length ? 'loaded' : 'loading'">
        @if ((targetedBrandingCampaigns$ | async )?.length === 0 && (videoExposureCampaigns$ | async)?.length === 0) {
        <div class="no-data">
            You are currently not running any Web campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortTargetedBranding="matSort" [tableSort]="sortTargetedBranding" [dataToDisplay]="targetedBrandingCampaigns$ | async"
                           [header]="'Targeted Branding'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="impressions">
                <th mat-header-cell *matHeaderCellDef> Impressions </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).impressions | number }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="reportingCompleteDateMillis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Reporting </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).reportingCompleteDateMillis | date: 'MM-dd-yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="webColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: webColumns"></tr>
        </app-wrapper-table>
        <app-wrapper-table matSort #sortVideoExposure="matSort" [tableSort]="sortVideoExposure" [dataToDisplay]="videoExposureCampaigns$ | async"
                           [header]="'Video Exposure'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="impressions">
                <th mat-header-cell *matHeaderCellDef> Impressions </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).impressions | number }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="reportingCompleteDateMillis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Reporting </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).reportingCompleteDateMillis | date: 'MM-dd-yyyy' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="webColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: webColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="EBOOKS" [labelClass]="(ebookCampaigns$ | async )?.length ? 'loaded' : 'loading'">
        @if ((ebookCampaigns$ | async )?.length === 0) {
        <div class="no-data">
            You are currently not running any Ebook campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortEbooks="matSort" [tableSort]="sortEbooks" [dataToDisplay]="ebookCampaigns$ | async"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="leadCount">
                <th mat-header-cell *matHeaderCellDef> Leads </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).leadCount }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).getFormattedDate() }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="ebookColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: ebookColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="WEBINARS" [labelClass]="(webinarCampaigns$ | async )?.length ? 'loaded' : 'loading'">
        @if ((webinarCampaigns$ | async )?.length === 0) {
        <div class="no-data">
            You are currently not running any Webinar campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortWebinars="matSort" [tableSort]="sortWebinars" [dataToDisplay]="webinarCampaigns$ | async"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="leadCount">
                <th mat-header-cell *matHeaderCellDef> Leads </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).leadCount }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).getFormattedDate() }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="webinarColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: webinarColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="PRINT" [labelClass]="(printCampaigns$ | async )?.length ? 'loaded' : 'loading'">
        @if ((printCampaigns$ | async )?.length === 0) {
        <div class="no-data">
            You are currently not running any Print campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortPrint="matSort" [tableSort]="sortPrint" [dataToDisplay]="printCampaigns$ | async"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', }, }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="distribution">
                <th mat-header-cell *matHeaderCellDef> Distribution </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).distribution | number }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).brand }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).getFormattedDate() }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="printColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: printColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="BOOTH SCANS & ONLINE SHOWROOM" [labelClass]="(tradeShowCampaigns$ | async )?.length ? 'loaded' : 'loading'">
        @if ((tradeShowCampaigns$ | async )?.length === 0) {
        <div class="no-data">
            You are currently not running any Trade Show campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortTradeShow="matSort" [tableSort]="sortTradeShow" [dataToDisplay]="tradeShowCampaigns$ | async"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               {
                                   property: 'tradeShowFullName',
                                   filter: {
                                       label: 'Show', options: (tradeShowSelectOptions$ | async), type: 'select',
                                       keysAreStrings: true,
                                   },
                               }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    @if (campaignLinkFormatter(element); as renderedLink) {
                        <a [routerLink]="renderedLink">
                            {{ (element | asType : Campaign).name }}
                        </a>
                    } @else {
                        {{ (element | asType : Campaign).name }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="leadCount">
                <th mat-header-cell *matHeaderCellDef> Leads </th>
                <td mat-cell *matCellDef="let element">
                    <strong>{{ (element | asType : Campaign).leadCount }}</strong>
                </td>
            </ng-container>

            <ng-container matColumnDef="tradeShowFullName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Show </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).tradeShowFullName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaign Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : Campaign).getFormattedDate() }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="boothScanColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: boothScanColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
    <mat-tab label="GAME PLAN · SECOND LOOK · FACILITIES & INFRASTRUCTURE"
             [labelClass]="(gamePlanTradeShowReports$ | async )?.length || (secondLookTradeShowReports$ | async)?.length || (facilitiesAndInfrastructureTradeShowReports$ | async)?.length ? 'loaded' : 'loading'"
    >
        @if ((gamePlanTradeShowReports$ | async )?.length === 0 && (secondLookTradeShowReports$ | async)?.length === 0 && (facilitiesAndInfrastructureTradeShowReports$ | async)?.length === 0) {
        <div class="no-data">
            You are currently not running any Game Plan, Second Look, or Facilities & Infrastructure campaigns with us.
            <br />
            Reach out to your sales representative to find out more.
        </div>
        }
        <app-wrapper-table matSort #sortGamePlan="matSort" [tableSort]="sortGamePlan" [dataToDisplay]="gamePlanTradeShowReports$ | async"
                           [header]="'Game Plan'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               {
                                   property: 'tradeShowFullName',
                                   filter: {
                                       label: 'Show', options: (tradeShowSelectOptionsLimited$ | async), type: 'select',
                                       keysAreStrings: true,
                                   },
                               }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="(element | asType : TradeShowReport).gamePlanPastLink">
                        {{ (element | asType : TradeShowReport).name }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).categoryName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="recipients">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipients </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).recipients | number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tradeShowFullName">
                <th mat-header-cell *matHeaderCellDef> Show </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).tradeShowFullName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="publishDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).formatedPublishDate }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="gpSlColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: gpSlColumns"></tr>
        </app-wrapper-table>
        <app-wrapper-table matSort #sortSecondLook="matSort" [tableSort]="sortSecondLook" [dataToDisplay]="secondLookTradeShowReports$ | async"
                           [header]="'Second Look'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               {
                                   property: 'tradeShowFullName',
                                   filter: {
                                       label: 'Show', options: (tradeShowSelectOptionsLimited$ | async), type: 'select',
                                       keysAreStrings: true,
                                   },
                               }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="(element | asType : TradeShowReport).secondLookPastLink">
                        {{ (element | asType : TradeShowReport).name }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
                <th mat-header-cell *matHeaderCellDef> Category </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).categoryName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="recipients">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipients </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).recipients | number }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tradeShowFullName">
                <th mat-header-cell *matHeaderCellDef> Show </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).tradeShowFullName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="publishDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).formatedPublishDate }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="gpSlColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: gpSlColumns"></tr>
        </app-wrapper-table>
        <app-wrapper-table matSort #sortFacilitiesAndInfra="matSort" [tableSort]="sortFacilitiesAndInfra" [dataToDisplay]="facilitiesAndInfrastructureTradeShowReports$| async"
                           [header]="'Facilities & Infrastructure'"
                           [hideIfEmpty]="true"
                           [columnConfigs]="[
                               {
                                   property: 'tradeShowFullName',
                                   filter: {
                                       label: 'Show', options: (tradeShowSelectOptionsLimited$ | async), type: 'select',
                                       keysAreStrings: true,
                                   },
                               }
                           ]"
        >
            <ng-container matColumnDef="advertiserName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Advertiser </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="['/advertiser', (element | asType: Campaign)?.aid, 'past-campaigns']">
                        {{ (element | asType : Campaign).advertiserName }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="(element | asType : TradeShowReport).facilitiesAndInfrastructurePastLink">
                        {{ (element | asType : TradeShowReport).name }}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="tradeShowFullName">
                <th mat-header-cell *matHeaderCellDef> Show </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).tradeShowFullName }}
                </td>
            </ng-container>

            <ng-container matColumnDef="publishDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish Date </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element | asType : TradeShowReport).formatedPublishDate }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="facilitiesAndInfraColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: facilitiesAndInfraColumns"></tr>
        </app-wrapper-table>
    </mat-tab>
</mat-tab-group>

<h1>
    View as User
</h1>
<mat-divider></mat-divider>

<button mat-button (click)="viewAsSalesRep()">View as Sales Rep (Single Company)</button>
<button mat-button (click)="viewAsSalesRep2()">View as Sales Rep (Multi-Company)</button>
<button mat-button (click)="viewAsCSM()">View as CSM (Non-Admin CSM)</button>

<mat-divider></mat-divider>

<h1>
    View as Specific User
</h1>

<div data-field-type="view-as-user-id-field">
    <label for="specific-user">User ID:</label>
    <input type="number" id="specific-user" [(ngModel)]="specificUserId">
</div>
<button mat-button (click)="viewAsSpecificUser()">View as specific User</button>
<mat-divider></mat-divider>

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable, map, ReplaySubject, combineLatest, mergeMap, } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { IframeComponent } from 'components/iframe/iframe.component';
import { IAdvertiser } from 'model';
import { AdvertiserService } from 'services';
import { environment, } from 'util';

@Component({
    selector: 'app-analysis',
    standalone: true,
    imports: [
        CommonModule,
        BreadcrumbComponent,
        IframeComponent,
    ],
    templateUrl: './analysis.component.html',
    styleUrl: './analysis.component.scss'
})
export class AnalysisComponent {
    private apiUrl = environment.apiUrl;

    private urlSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
    protected url$: Observable<string> = this.urlSubject.asObservable();

    private advertiserIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    private campaignIdSubject: ReplaySubject<number> = new ReplaySubject<number>(1);

    protected breadcrumbs$: Observable<Breadcrumb[]> = combineLatest([
        this.advertiserIdSubject.pipe(
            mergeMap((advertiserId: number) => this.advertiserService.getAdvertiser(advertiserId))
        ),
        this.campaignIdSubject
    ]).pipe(
        map(([advertiser, campaignId]: [IAdvertiser, number]) => {
            return [
                { label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Past Campaigns', url: `/advertiser/${advertiser.aid}/past-campaigns` },
                { label: 'Campaign', url: `/advertiser/${advertiser.aid}/past-campaigns/campaign/${campaignId}` },
                { label: 'Analysis', url: '' },
            ];
        })
    );


    @Input()
    set advertiserId(advertiserId: number) {
        this.advertiserIdSubject.next(advertiserId);
    }

    @Input()
    set campaignId(campaignId: number) {
        this.urlSubject.next(`${this.apiUrl}/leads/analysis?cid=${campaignId}&api=true`);
        this.campaignIdSubject.next(campaignId);
    }

    constructor(
        private advertiserService: AdvertiserService,
    ) {}
}

<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Upcoming Campaigns</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if ((dataToDisplay$ | async)?.length === 0) {
<div class="no-data">
    You currently do not have any upcoming campaigns.
    <br />
    Reach out to your sales representative to find out more.
</div>
}

<app-wrapper-table matSort #sort="matSort" [tableSort]="sort"
                   [dataToDisplay]="dataToDisplay$ | async"
                   [hideIfEmpty]="true"
                   [columnConfigs]="[
                       { property: 'brandId', filter: { label: 'Brand', options: brands, type: 'select', } },
                       { property: 'type', filter: { label: 'Campaign Type', options: campaignTypes, type: 'select', } }
                   ]"
>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaign Date </th>
        <td mat-cell *matCellDef="let element" [style]="alertStyleFormatter(element, 'date')">
            {{ (element | asType : Campaign).getFormattedDate() }}
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
            @if (campaignLinkFormatter(element); as renderedLink) {
                <a [routerLink]="renderedLink">
                    {{ (element | asType : Campaign).name }}
                </a>
            } @else {
                {{ (element | asType : Campaign).name }}
            }
        </td>
    </ng-container>

    <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : Campaign).brand }}
        </td>
    </ng-container>

    <ng-container matColumnDef="campaignType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let element">
            <strong>{{ (element | asType : Campaign).campaignType }}</strong>
        </td>
    </ng-container>

    <ng-container matColumnDef="materialsDue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Materials Due </th>
        <td mat-cell *matCellDef="let element" [style]="alertStyleFormatter(element, 'materialsDue')">
            <strong>{{ (element | asType : Campaign).materialsDueMillis | date: 'MM-dd-yyyy' }}</strong>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element" [class]="alertClassFormatter(element)" [style]="alertStyleFormatter(element, 'action')">
            @if (actionLinkFormatter(element | asType : Campaign); as actionLink) {
                @if (isLinkInternal(actionLink)) {
                    <strong>
                        <a [routerLink]="actionLink" [style]="alertStyleFormatter(element, 'action')">
                            {{ actionFormatter(element | asType : Campaign) }}
                        </a>
                    </strong>
                } @else {
                    <strong>
                        <a [href]="actionLink" target="_blank" [style]="alertStyleFormatter(element, 'action')">
                            {{ actionFormatter(element | asType : Campaign) }}

                            @if (isMailtoLink(actionLink)) {
                                <mat-icon>email</mat-icon>
                            } @else {
                                <mat-icon>open_in_new</mat-icon>
                            }
                        </a>
                    </strong>
                }
            }
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['date', 'name', 'brand', 'campaignType', 'materialsDue', 'action']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['date', 'name', 'brand', 'campaignType', 'materialsDue', 'action']"
        [class]="alertClassFormatter(row)"
    ></tr>
</app-wrapper-table>


import { CommonModule } from '@angular/common';
import {
    Component, ViewChild, Renderer2, HostListener, Input, AfterViewInit, ElementRef, Output, EventEmitter
} from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, BehaviorSubject, } from 'rxjs';

import { SafePipe } from 'util';

@Component({
    selector: 'app-iframe',
    standalone: true,
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        SafePipe,
    ],
    templateUrl: './iframe.component.html',
    styleUrl: './iframe.component.scss'
})
export class IframeComponent implements AfterViewInit {
    #loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected loading$: Observable<boolean> = this.#loadingSubject.asObservable();

    @ViewChild('campaignIframe') campaignIframe!: ElementRef<HTMLIFrameElement>;

    @Input() src: string | null = '';

    @Input() width: string | null = '100%';

    @Output() iframeLoaded = new EventEmitter();
    @Output() iframeNavigating = new EventEmitter();

    // add listener for browser resize event
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.campaignIframe?.nativeElement.contentWindow?.postMessage({ type: 'iframeResize' }, '*');
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        this.campaignIframe?.nativeElement.contentWindow?.postMessage(
            { type: 'iframeScroll', position: window.scrollY, },
            '*'
        );
    }

    constructor(private renderer: Renderer2) {
        window.addEventListener('message', (event) => {
            if (event.data.type === 'iframeHeight') {
                this.renderer.setStyle(this.campaignIframe.nativeElement, 'height', event.data.height + 80 + 'px');
            }
            if (event.data.type === 'iframeNavigating') {
                this.iframeNavigating.emit();
                this.#loadingSubject.next(true);
            }
            if (event.data.type === 'iframeLoaded') {
                this.iframeLoaded.emit();
                this.#loadingSubject.next(false);
            }
        });
    }

    ngAfterViewInit() {
        this.campaignIframe.nativeElement.onload = () => {
            this.iframeLoaded.emit();
            this.#loadingSubject.next(false);
        };
    }
}

import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Observable, map, ReplaySubject, } from 'rxjs';

import { BreadcrumbComponent, Breadcrumb } from 'components/breadcrumb/breadcrumb.component';
import { IframeComponent } from 'components/iframe/iframe.component';
import { IAdvertiser } from 'model';
import { AdvertiserService, CampaignService } from 'services';
import { environment, } from 'util';

@Component({
    selector: 'app-media-discovery-review',
    standalone: true,
    imports: [
        CommonModule,
        BreadcrumbComponent,
        IframeComponent,
    ],
    templateUrl: './review.component.html',
    styleUrl: './review.component.scss'
})
export class MediaDiscoveryReviewComponent {
    private apiUrl = environment.apiUrl;

    private urlSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
    protected url$: Observable<string> = this.urlSubject.asObservable();

    protected breadcrumbs$!: Observable<Breadcrumb[]>;

    @Input()
    set advertiserId(advertiserId: number) {
        this.breadcrumbs$ = this.advertiserService.getAdvertiser(advertiserId).pipe(
            map((advertiser: IAdvertiser) => [
                { label: advertiser.name, url: `/advertiser/${advertiser.aid}/upcoming-campaigns` },
                { label: 'Reports & Tools', url: `/advertiser/${advertiser.aid}/reports-and-tools`, },
                { label: 'Media Discovery Tool', url: `/advertiser/${advertiser.aid}/reports-and-tools/media-discovery-tool`, },
                { label: 'Review', url: '' },
            ])
        );
    }

    @Input()
    set quoteId(quoteId: number) {
        this.urlSubject.next(`${this.apiUrl}/_/api/media-discovery-quote/review?id=${quoteId}`);
    }

    constructor(
        private campaignService: CampaignService,
        private advertiserService: AdvertiserService,
    ) {
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'percentage',
    standalone: true
})
export class PercentagePipe implements PipeTransform {
    transform(value: number | null): string {
        if (value === null) {
            return '';
        }
        return (value * 100).toFixed(1) + '%'
    }
}

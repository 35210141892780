import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle, } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { Router } from '@angular/router';
import { catchError } from 'rxjs';
import { StartViewingAsUserStatus, UserService } from 'services';


@Component({
    selector: 'app-view-as-user-dialog',
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatDivider
    ],
    templateUrl: './view-as-user-dialog.component.html',
    styleUrl: './view-as-user-dialog.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAsUserDialogComponent {
    protected specificUserId: number = 0;

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        // Do nothing for now
    }

    viewAsSalesRep() {
        this.viewAsUser(12310);
    }

    viewAsSalesRep2() {
        this.viewAsUser(12311);
    }

    viewAsCSM() {
        this.viewAsUser(12312);
    }

    viewAsSpecificUser() {
        this.viewAsUser(this.specificUserId);
    }

    viewAsUser(userIdToView: number) {
        if (userIdToView === 0) {
            alert('Need to add a User ID to view.');
            return;
        }

        // View as user
        this.userService.requestStartViewingAsUser(userIdToView)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.problem || '';
                    let hasMessage = errorMessage.length > 0;

                    // Handle the failure states
                    switch (responseStatus) {
                        case StartViewingAsUserStatus.Failed:
                            if (hasMessage) {
                                alert('Failed to view as user: ' + errorMessage);
                            } else {
                                alert('Failed to view as user.');
                            }
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.problem || '';

                // Handle the success states
                switch (response.status) {
                    case StartViewingAsUserStatus.Success:
                        //void this.router.navigate(['/']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/'; // Go to landing page on a new page load
                        break;
                    default:
                        break;
                }
            });
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'asType',
    standalone: true,
})
export class AsTypePipe implements PipeTransform {
    /**
     * @see https://stackoverflow.com/a/71473601/2267584
     */
    transform<T>(value: unknown, classOfType: new (...args: any[]) => T): T {
        return value as T;
    }
}

<div class="content">
    <div class="equal-column gray-background">
        <div class="slide-to-center">
            <div class="login-area">
                <div class="login-logo-container">
                    <img class="login-logo-left login-logo-leadworks-logo" ngSrc="assets/leadworks_logo.png" alt="Leadworks" height="30" width="180">
                    <img class="login-logo-right" ngSrc="assets/pmg_logo_2.png" alt="Leadworks" width="150" height="72.6"> <!-- Real size: width="500" height="242" -->
                </div>
                <div class="login-card">
                    <h2>Login to your account</h2>
                    <div class="login-explainer-text">Manage campaigns, track results, and view reports all in one place.</div>
                    @if (showMessage) {
                        <div class="login-message" [attr.data-message-type]="messageType">{{message}}</div>
                    }
                    <div class="field" data-field-type="login-username">
                        <div class="slide-to-left"><label for="username">Username</label></div>
                        <div class="input-stretcher">
                            <input type="text" id="username" name="username" [(ngModel)]="usernameValue">
                        </div>
                        <div class="slide-to-right"><a href="/forgot-password">Forgot Password?</a></div>
                    </div>
                    <div class="field" data-field-type="login-password">
                        <div class="slide-to-left"><label for="password">Password</label></div>
                        <div class="input-stretcher">
                            <input type="password" id="password" name="password" [(ngModel)]="passwordValue">
                        </div>
                    </div>
                    <div class="field" data-field-type="checkbox" style="display: none;">
                        <input type="checkbox" id="remember-me" name="remember-me" [(ngModel)]="rememberMeValue">
                        <label for="remember-me">Remember Me?</label>
                    </div>
                    <div class="field" data-field-type="submit">
                        <button mat-flat-button (click)="handleLoginClick(usernameValue, passwordValue, rememberMeValue)">Login</button>
                    </div>
                    <hr>
                    <div>
                        Need an account? <a href="mailto:clientsuccess@pmmimediagroup.com?subject=LeadWorks%20account%20needed&body=Hello!%0D%0A%0D%0AI'm%20looking%20to%20get%20a%20new%20account%20set%20up%20on%20LeadWorks%2C">Click here.</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="equal-column">
        <div class="info-box">
            <div class="lead-text">LeadWorks Update</div>
            <h1>Welcome to LeadWorks</h1>
            <div class="intro-text">Find all your PMMI, PMMI Media Group, and PACK EXPO resources in one place.</div>
            <ul>
                <li>Access advertiser and exhibitor dashboards</li>
                <li>Download leads</li>
                <li>Submit materials</li>
                <li>View reports</li>
            </ul>
            <div class="slide-to-center">
                <img class="login-pmmi-logo" ngSrc="assets/login_right.png" alt="Leadworks" width="535" height="363">
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="copyright-notice">
        {{copyrightNotice}}
    </div>
</footer>

import { AsyncPipe, NgIf, NgOptimizedImage } from "@angular/common";
import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { IUser } from 'model/user';
import {
    GetIsViewingAsUserStatus,
    StartViewingAsUserStatus, StopViewingAsUserStatus,
    UserLogOutStatus,
    UserService
} from 'services/user.service';
import { ViewAsUserDialogComponent } from 'content/view-as-user-dialog/view-as-user-dialog.component';
import { DROPDOWN_CHEVRON_DOWN } from 'layouts/sidebar-nav/svg-icons';

@Component({
    selector: 'top-nav',
    standalone: true,
    imports: [
        MatToolbarModule,
        NgOptimizedImage,
        MatMenuModule,
        MatButtonModule,
        NgIf,
        AsyncPipe,
        MatIconModule,
        RouterLink,
    ],
    templateUrl: './top-nav.component.html',
    styleUrl: './top-nav.component.scss',
})
export class TopNavComponent {
    protected user$: Observable<IUser> = this.userService.getLoggedInUser();
    protected isAdmin$: Observable<boolean> = this.userService.isLoggedInUserAnAdmin();
    readonly dialog = inject(MatDialog);
    protected isViewingAsUser: boolean | null = null;

    constructor(
        private userService: UserService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private router: Router
    ) {
        this.matIconRegistry.addSvgIconLiteral('dropdown-chevron-down', this.domSanitizer.bypassSecurityTrustHtml(DROPDOWN_CHEVRON_DOWN));

        // Check if view-as-user is on
        if (this.isViewingAsUser === null) {
            this.checkIsViewingAsUser();
        }
    }

    public logout(): void {
        this.userService.requestLogOut()
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;

                    // Handle the failure states
                    switch (responseStatus) {
                        case UserLogOutStatus.Failed:
                        case UserLogOutStatus.Conflict:
                        case UserLogOutStatus.Exception:
                            console.log('Logout failed');
                            break;
                        default:
                            break;
                    }

                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;

                // Handle the success states
                switch (response.status) {
                    case UserLogOutStatus.Success:
                        // void this.router.navigate(['/login']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/login'; // Go to the login page on a new page load
                        break;
                    case UserLogOutStatus.AlreadyLoggedOut:
                        // void this.router.navigate(['/login']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/login'; // Go to the login page on a new page load
                        break;
                    default:
                        console.log('Logout encountered an unknown problem');
                        break;
                }
            });
    }

    openViewAsUserDialog() {
        const dialogConfig: {} = {
            autoFocus: false
        }

        this.dialog.open(ViewAsUserDialogComponent, dialogConfig);
    }

    protected checkIsViewingAsUser(): void {
        this.userService.requestGetIsViewingAsUser()
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;

                    // Handle the failure states
                    switch (responseStatus) {
                        case GetIsViewingAsUserStatus.Failed:
                            console.log('Failed to check view-as-user');
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;

                // Handle the success states
                switch (response.status) {
                    case GetIsViewingAsUserStatus.Yes:
                        this.isViewingAsUser = true;
                        break;
                    case GetIsViewingAsUserStatus.No:
                        this.isViewingAsUser = false;
                        break;
                    default:
                        console.log('view-as-user encountered an unknown problem');
                        break;
                }
            });
    }

    returnToAdmin() {
        // Call Return-to-Admin
        this.userService.requestStopViewingAsUser()
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.problem || '';
                    let hasMessage = errorMessage.length > 0;

                    // Handle the failure states
                    switch (responseStatus) {
                        case StopViewingAsUserStatus.Failed:
                            if (hasMessage) {
                                alert('Failed to view as user: ' + errorMessage);
                            } else {
                                alert('Failed to view as user.');
                            }
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.problem || '';

                // Handle the success states
                switch (response.status) {
                    case StopViewingAsUserStatus.Success:
                        //void this.router.navigate(['/']);
                        window.location.href = window.location.protocol + '//' + window.location.host + '/'; // Go to landing page on a new page load
                        break;
                    default:
                        break;
                }
            });
    }
}

import { CommonModule } from '@angular/common';
import { Component, } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { RouterLink } from '@angular/router';
import { MatTableModule, } from '@angular/material/table';

import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, } from 'components/wrapper-table';
import { Advertiser, } from 'model';
import { map, Observable } from 'rxjs';
import { UserService } from 'services';
import { AsTypePipe, BrandsPipe } from 'util';

@Component({
    selector: 'app-my-advertisers',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        BreadcrumbComponent,
        WrapperTableComponent,
        MatTableModule,
        MatSortModule,
        AsTypePipe,
        RouterLink,
        BrandsPipe,
    ],
    templateUrl: './my-advertisers.component.html',
    styleUrl: './my-advertisers.component.scss'
})
export class MyAdvertisersComponent {
    protected user$ = this.userService.getLoggedInUser();

    protected breadcrumbs$: Observable<Breadcrumb[]> = this.user$.pipe(
        map(user => [
            { label: `${user.firstName} ${user.lastName}`, url: '' },
            { label: 'My Advertisers', url: '' },
        ])
    );

    protected dataToDisplay$: Observable<Advertiser[]> = this.user$.pipe(
        map(user => user.sortedAdvertisers as Advertiser[])
    );

    constructor(
        private userService: UserService
    ) {}

    protected readonly Advertiser = Advertiser;
}

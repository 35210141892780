<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Exhibitor Resource Center</h1>

<mat-divider class="page-title-divider"></mat-divider>

@if (!(tradeShows$ | async)) {
    <div class="loader">
        <ngx-skeleton-loader count="8" appearance="line" [theme]="{height: '64px'}"></ngx-skeleton-loader>
    </div>
}

<app-card-list>
    @for (tradeShow of tradeShows$ | async; track tradeShow.id; let isLast = $last) {
        <app-card-item>
            <app-card-item-icon>
                <mat-icon [svgIcon]="tradeShow.showType" style="width: 54px; height: auto;" />
            </app-card-item-icon>
            <app-card-item-title-text>
                {{ tradeShow.fullNameMinusYear }}
            </app-card-item-title-text>
            @if (tradeShow.exhibitorPortalUrl) {
                <app-card-item-link>
                    <a mat-flat-button color="primary" class="pmg-primary-dark" [href]="tradeShow.exhibitorPortalUrl" target="_blank">
                        VISIT EXHIBITOR RESOURCE CENTER <mat-icon>open_in_new</mat-icon>
                    </a>
                </app-card-item-link>
            }
        </app-card-item>
    }
</app-card-list>

import { CommonModule } from '@angular/common';
import { Component, } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule, } from '@angular/material/table';

import { Breadcrumb, BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { WrapperTableComponent, } from 'components/wrapper-table';
import {
    Advertiser,
    BrandSelectOptions,
    Campaign,
    CampaignList,
    CampaignTypeSelectOptions,
    IAdvertiser,
    ICampaign,
} from 'model';
import { map, Observable, Subscription, mergeMap, combineLatest, } from 'rxjs';
import { AdvertiserService, CampaignService, UserService, } from 'services';
import { AsTypePipe, } from 'util';

@Component({
    selector: 'app-sales-rep-upcoming-campaigns',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        BreadcrumbComponent,
        WrapperTableComponent,
        AsTypePipe,
        MatTableModule,
        MatSortModule,
        RouterLink,
        MatIconModule,
    ],
    templateUrl: './sales-rep-upcoming-campaigns.component.html',
    styleUrl: './sales-rep-upcoming-campaigns.component.scss'
})
export class SalesRepUpcomingCampaignsComponent {
    protected subscriptions : Subscription[] = [];

    protected breadcrumbs$: Observable<Breadcrumb[]> = this.userService.getLoggedInUser().pipe(
        map(user => [
            { label: `${user.firstName} ${user.lastName}`, url: '' },
            { label: 'My Advertisers Upcoming Campaigns', url: '' },
        ])
    );

    protected dataToDisplay$: Observable<CampaignList> = this.userService.getLoggedInUser().pipe(
        map(user => user.advertisers.map(advertiser => advertiser.aid)),
        mergeMap(aids => combineLatest([
            this.advertiserService.getAdvertisers(...aids),
            this.campaignService.getUpcomingCampaigns(...aids),
        ])),
        map(([advertisers, campaigns]: [IAdvertiser[], ICampaign[]]) => {
            // Convert IAdvertiser[] to Advertiser[] and create a Map of advertisers indexed by aid
            const advertiserMap = new Map(advertisers.map(advertiser => [advertiser.aid, new Advertiser(advertiser)]));
            // Convert ICampaign[] to Campaign[] and set the advertiser property of each campaign
            campaigns = campaigns.map(campaign => new Campaign({
                ...campaign,
                advertiser: advertiserMap.get(campaign.aid)
            }));
            return new CampaignList(...campaigns);
        })
    );

    protected readonly brands: { id: any, name: string }[] = BrandSelectOptions;
    protected readonly campaignTypes: { id: any, name: string }[] = CampaignTypeSelectOptions;
    protected readonly Campaign = Campaign;

    constructor(
        private userService: UserService,
        private advertiserService: AdvertiserService,
        private campaignService: CampaignService
    ) {}
}

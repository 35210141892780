import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { ResetPasswordStatus, UserService, VerifyUserResetPasswordTokenStatus } from 'services';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    standalone: true,
    imports: [
        NgOptimizedImage,
        ReactiveFormsModule,
        FormsModule,
        MatButton
    ],
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
    protected _copyrightNotice!: string;
    protected showMessage: boolean = false;
    protected messageType: string = 'default';
    protected message: string = '';
    protected resetToken: string = '';
    protected didCheckToken: boolean = false;
    protected isResetTokenValid: boolean = false;
    protected didResetPassword: boolean = false;
    protected userId: number = 0;
    protected username: string = '';
    protected userEmail: string = '';
    protected newPasswordValue: string = '';
    protected confirmNewPasswordValue: string = '';

    constructor(
        private userService: UserService,
        private router: Router
    ) {
        if (!this.didCheckToken) {
            // Only run once, not every time the constructor gets re-run
            this.didCheckToken = true;

            // Check the token
            this.checkToken();
        }
    }

    protected checkToken(): void {
        // Show message that we're checking the reset token
        this.showLoginMessage('info', 'Checking password reset token...');

        // Get the reset token from URL
        let queryArgs: { [key: string]: any } = this.getUrlVariables();
        let hasResetToken: boolean = queryArgs.hasOwnProperty('reset');
        let resetToken: string = (hasResetToken) ? queryArgs['reset'] : '';

        // Check that the reset token exists
        if (!hasResetToken) {
            this.showLoginMessage('error', 'A reset token was not provided.');
            return;
        }

        // Remember the reset token for later
        this.resetToken = resetToken;

        // Get if the token is valid
        this.userService.requestVerifyResetPasswordToken(resetToken)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.message || '';

                    // Handle the failure states
                    switch (responseStatus) {
                        case VerifyUserResetPasswordTokenStatus.Failed:
                            this.showLoginMessage('error', errorMessage);
                            break;
                        case VerifyUserResetPasswordTokenStatus.Conflict:
                            this.showLoginMessage('error', errorMessage);
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.message || '';
                const userId = body?.userId || 0;
                const username = body?.username || '';
                const userEmail = body?.userEmail || '';

                // Handle the success states
                switch (response.status) {
                    case VerifyUserResetPasswordTokenStatus.Success:
                        this.showLoginMessage('success', message);

                        this.isResetTokenValid = true;
                        this.userId = userId;
                        this.username = username;
                        this.userEmail = userEmail;

                        this.showMessage = false;
                        break;
                    default:
                        break;
                }
            });
    }

    protected showLoginMessage(messageType: string, message: string): void {
        this.message = message;
        this.messageType = messageType;
        this.showMessage = true;
    }

    get copyrightNotice(): string {
        if (this._copyrightNotice) {
            return this._copyrightNotice;
        }

        let year: number = new Date().getFullYear();
        this._copyrightNotice = `Copyright © ${year} PMMI Media Group. All rights reserved.`;

        return this._copyrightNotice;
    }

    protected getUrlVariables(): { [key: string]: any } {
        // Get URL info
        let urlVariables: any[] = [];
        let url: string = window.location.href;
        let urlQuery: string = url.slice(url.indexOf('?') + 1);
        let queryArgs: string[] = urlQuery.split('&');

        // Loop through the URL GET arguments
        let queryArg: { [key: string]: any };
        for (let i = 0; i < queryArgs.length; i++) {
            queryArg = queryArgs[i].split('=');
            urlVariables[queryArg[0]] = queryArg[1];
        }

        // Return array of GET variables
        return urlVariables;
    }

    handleSubmitResetClick(givenNewPassword: string, givenConfirmNewPassword: string): void {
        this.showLoginMessage('info', 'Resetting password...');

        let hasPassword = givenNewPassword.length > 0;
        let hasConfirmPassword = givenConfirmNewPassword.length > 0;
        let isConfirmPasswordTheSame = givenNewPassword === givenConfirmNewPassword;

        // Check that the password exists
        if (!hasPassword) {
            this.showLoginMessage('warning', 'A new password was not provided.');
            return;
        }

        // Check that the confirm-password exists
        if (!hasConfirmPassword) {
            this.showLoginMessage('warning', 'Confirm password was not provided.');
            return;
        }

        // Check that the confirm-password matches the new password
        if (!isConfirmPasswordTheSame) {
            this.showLoginMessage('error', 'New password and confirm password do not match.');
            return;
        }

        // Check that the password is long enough, the real limit on the PHP side is less than 12 now, but 12 is that old number that was used
        if (givenNewPassword.length < 12) {
            this.showLoginMessage('warning', 'The password needs to be 12 characters or more.');
            return;
        }

        // Reset the password
        this.userService.requestResetPassword(this.resetToken, givenNewPassword, givenConfirmNewPassword)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let responseStatus = error.status;
                    let errorMessage = error.error?.message || '';

                    // Handle the failure states
                    switch (responseStatus) {
                        case ResetPasswordStatus.Failed:
                            this.showLoginMessage('error', errorMessage);
                            break;
                        case ResetPasswordStatus.Conflict:
                            this.showLoginMessage('error', errorMessage);
                            break;
                        default:
                            break;
                    }
                    throw error;
                })
            )
            .subscribe((response) => {
                const body = response.body;
                const message = body?.message || '';

                // Handle the success states
                switch (response.status) {
                    case ResetPasswordStatus.Success:
                        this.didResetPassword = true;
                        this.showLoginMessage('success', message);
                        break;
                    default:
                        break;
                }
            });
    }

    handleGoToLoginClick(): void {
        // Redirect to the login page
        void this.router.navigate(['/login']);
    }
}

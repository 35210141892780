import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subscription, } from 'rxjs';

import { BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { UserService } from 'services';
import { environment } from 'util';

@Component({
    selector: 'app-router-hub',
    standalone: true,
    imports: [
        MatDividerModule,
        BreadcrumbComponent,
        NgxSkeletonLoaderModule,
    ],
    templateUrl: './router-hub.component.html',
    styleUrl: './router-hub.component.scss'
})
export class RouterHubComponent implements OnInit, OnDestroy {
    #subscriptions: Subscription[] = [];
    #apiUrl = environment.apiUrl;

    protected breadcrumbs = [{ label: 'Home', url: '' }];

    constructor (
        private userService: UserService,
        private router: Router
    ) {}

    public ngOnInit() : void {
        const subscription = this.userService.getLoggedInUser().subscribe((user) => {
            if (user.isSalesRep) {
                //void this.router.navigate(['/user', user.id, 'my-advertisers', 'upcoming-campaigns']);
                void this.router.navigate(['/user', user.id, 'my-advertisers']);
            } else if (user.isMultiAdvertiserUser) {
                void this.router.navigate(['/user', user.id, 'my-advertisers']);
            } else if (user.hasAdvertiser()) {
                void this.router.navigate(['/advertiser', user.advertisers[0].aid, 'upcoming-campaigns', ]);
            } else {
                window.location.href = `${this.#apiUrl}/_/advertiser`;
            }
        });
        this.#subscriptions.push(subscription);
    }

    public ngOnDestroy() : void {
        this.#subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}

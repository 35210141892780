import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    Injectable,
    Input,
    OnDestroy,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { $localize } from '@angular/localize/init';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import {
    MatCellDef,
    MatColumnDef,
    MatHeaderRowDef,
    MatRowDef,
    MatTable,
    MatTableModule,
} from '@angular/material/table';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { combineLatest, first, map, Observable, ReplaySubject, startWith, Subject, Subscription } from 'rxjs';
import { SafePipe } from 'util';

import { TableDataSource, } from './index';

type FilterType = 'select' | 'input';
type FilterOptions = { id: any, name: string }[];
type FilterValue = { property: string, value: any };
type FilterValues = FilterValue[];
export interface ColumnConfig {
    property: string;
    filter?: {
        label: string;
        type: FilterType;
        options?: FilterOptions | null;
        keysAreStrings?: boolean;
    };
}

@Injectable()
export class CustomPaginator implements MatPaginatorIntl {
    changes = new Subject<void>();
    firstPageLabel: string = $localize`First Page`;
    itemsPerPageLabel: string = $localize`Results per page`;
    lastPageLabel: string = $localize`Last Page`;
    nextPageLabel: string = $localize`Next Page`;
    previousPageLabel: string = $localize`Previous Page`;

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return $localize`Page 1 of 1`;
        }

        const amountPages = Math.ceil(length / pageSize);
        return $localize`Displaying ${page + 1} of ${amountPages}`;
    }
}

@Component({
    selector: 'app-wrapper-table',
    standalone: true,
    imports: [
        SafePipe,
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
    ],
    templateUrl: './wrapper-table.component.html',
    styleUrl: './wrapper-table.component.scss',
    providers: [
        { provide: MatPaginatorIntl, useClass: CustomPaginator }
    ],
})
export class WrapperTableComponent<T> implements AfterContentInit, OnDestroy, AfterViewInit {
    protected dataSource!: TableDataSource;
    protected filterFormControls: { [key: string]: FormControl } = {};
    protected _dataToDisplay!: any[];
    protected _columnConfigs!: ColumnConfig[] | undefined;

    protected pageSizeOptions: number[] = [5, 10, 25, 100];
    protected pageSize: number = 25;

    protected subscriptions: Subscription[] = [];

    #sortSubject: ReplaySubject<Sort> = new ReplaySubject<Sort>(1);
    protected sort$: Observable<Sort> = this.#sortSubject.asObservable();

    #filterValuesSubject: ReplaySubject<FilterValues> = new ReplaySubject<FilterValues>(1);
    #filterValuesSubscription!: Subscription;
    #filterValues$: Observable<FilterValues> = this.#filterValuesSubject.asObservable();

    @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
    @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;
    @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;
    @ContentChildren(MatCellDef) cellDefs!: QueryList<MatCellDef>;

    @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
    @ViewChildren('formLabel') formLabels!: QueryList<ElementRef>;
    @ViewChild(MatTable, { static: true }) table!: MatTable<T>;
    @ViewChild(MatPaginator, { static: true }) tablePaginator!: MatPaginator;

    @Input() header?: string;

    @Input() hideIfEmpty: boolean = false;

    @Input()
    set tableSort(matSort: MatSort | null) {
        if (!matSort) { return; }
        const subscription = matSort.sortChange.subscribe((sort: Sort) => {
            this.#sortSubject.next(sort);
        });
        this.subscriptions.push(subscription);
    }

    @Input()
    set dataToDisplay(data: any[] | null) {
        if (!data) { return; }
        this._dataToDisplay = data;
        setTimeout(() => this.dataSource.updateData(this.filterPagesForPaginator(data)));
    }
    get dataToDisplay(): any[] { return this._dataToDisplay; }

    @Input()
    set columnConfigs(configs: ColumnConfig[] | null) {
        if (!configs) { return; }
        this._columnConfigs = configs;

        const filterObservables: Observable<FilterValue>[] = [];
        const filterableColumns: ColumnConfig[] = configs.filter(config => config.filter);
        filterableColumns.forEach(config => {
            if (!config.filter?.options) { return; }
            const filterControl = this.filterFormControls[config.property] = new FormControl();
            const filterObservable$ = filterControl.valueChanges.pipe(
                startWith(-1),
                map((value: any) => value === null ? -1 : value),
                map((value: any) => ({ property: config.property, value: value }))
            );
            filterObservables.push(filterObservable$);
        });

        if (this.#filterValuesSubscription) this.#filterValuesSubscription.unsubscribe();
        if (filterObservables.length === 0) { return; }
        this.#filterValuesSubscription = combineLatest(filterObservables)
            .subscribe((values: FilterValues) => {
                this.#filterValuesSubject.next(values);
            });
    }
    get columnConfigs(): ColumnConfig[] | undefined { return this._columnConfigs; }

    constructor(
        private renderer: Renderer2,
    ) {
        this.dataSource = new TableDataSource();
    }

    ngAfterContentInit() {
        this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
        this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
        this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    }

    ngAfterViewInit() {
        const subscription = combineLatest([
            this.dataSource.dataLength$.pipe(first()),
            this.tablePaginator.page.pipe(startWith(null)),
            this.sort$.pipe(startWith(null)),
            this.#filterValues$.pipe(startWith([{ property: '', value: -1 }])),
        ]).subscribe(([, , sort, filters]: [any, any, Sort | null, FilterValues]) => {
            const filter: { [key: string]: any } = {};
            filters.forEach(({ property, value }: FilterValue) => {
                if (!property) { return; }
                filter[property] = value;
                const formControl = this.filterFormControls[property];
                if (value === -1 && formControl.value !== null) {
                    formControl.setValue(null, { emitViewToModelChange: false });
                }
            });

            const data = this.dataToDisplay;
            const filteredData: any[] = this.filter(data, filter);
            const sortedData: any[] = this.sort(filteredData, sort);
            const paginatedData: any[] = this.filterPagesForPaginator(sortedData);

            this.dataSource.updateData(paginatedData);
        });
        this.subscriptions.push(subscription);

        const formLabelSubscription = this.formLabels.changes.subscribe(() => this.resizeFormFields());
        this.subscriptions.push(formLabelSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        if (this.#filterValuesSubscription) this.#filterValuesSubscription.unsubscribe();
    }

    protected filter(data: any[], filter: { [key: string]: any }): any[] {
        return data.filter((data: any) => {
            const keys = Object.keys(filter);
            return keys.every((key: string) => {
                const config = this.getColumnConfig(key);
                const filterValue: any = config?.filter?.keysAreStrings ? filter[key] : parseInt(filter[key]);
                return ['-1', -1].includes(filterValue) ? true : filterValue === data[key];
            });
        });
    }

    protected sort(data: any[], sort: Sort | null): any[] {
        if (!sort || !sort.active || sort.direction === '') {
            return data;
        }

        const compare = (a: any, b: any, isAsc: boolean) => {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        };

        return data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            const valueA = a[sort.active];
            const valueB = b[sort.active];
            return compare(valueA, valueB, isAsc);
        });
    }

    protected filterPagesForPaginator(data: any[]): any[] {
        this.dataSource.updateDataLength(data.length);

        let startIndex;
        let pageSize;
        if (!this.tablePaginator) {
            startIndex = 0;
            pageSize = this.pageSize;
        } else {
            startIndex = this.tablePaginator?.pageIndex * this.tablePaginator?.pageSize;
            pageSize = this.tablePaginator?.pageSize;
        }

        return data.slice(startIndex, startIndex + pageSize);
    }

    public resizeFormFields() {
        this.formLabels.forEach((label: ElementRef, index: number) => {
            const formField: ElementRef = this.formFields.toArray()[index];
            let labelWidth: number = label.nativeElement.offsetWidth;
            if (labelWidth === 0) {
                const text = label.nativeElement.innerText;
                labelWidth = text.length * 10;
            }
            this.renderer.setStyle(formField.nativeElement, 'width', `${labelWidth + 56}px`);
        });
    }

    protected getColumnConfig(property: string): ColumnConfig | undefined {
        return this.columnConfigs?.find(config => config.property === property);
    }
}

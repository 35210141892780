import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss'
})
export class NotFoundComponent implements OnInit {
    protected errorMessage: string = 'The page you are looking for does not exist';

    constructor(private router: Router) { }

    ngOnInit() {
        const navigation = this.router.lastSuccessfulNavigation;
        if (navigation?.extras?.state?.error) {
            this.errorMessage = navigation.extras.state.error;
        }
    }
}

import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-card-list',
    standalone: true,
    imports: [
        MatCardModule,
    ],
    template: `
        <mat-card>
            <ng-content></ng-content>
        </mat-card>
    `,
    styles: [`

    `]
})
export class CardListComponent {}

// noinspection UnnecessaryLocalVariableJS

import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
    protected copyrightNotice: string | undefined;

    constructor() {
        this.copyrightNotice = this.getCopyrightNotice();
    }

    getCopyrightNotice(): string {
        let year : number = new Date().getFullYear();
        let copyrightNotice : string = `Copyright © ${year} PMMI Media Group. All rights reserved.`

        // Return the copyright notice
        return copyrightNotice;
    }
}

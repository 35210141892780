/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

window.fetch = new Proxy(window.fetch, {
    apply(target, thisArg, args: any[]) {
        console.log('fetch', args, environment);
        const url = args[0];
        if (typeof url === 'string') {
            if (url.startsWith('/_/api')) {
                args[0] = `${environment.apiUrl}${url}`;
            }
            // replace any part of the url that matches environment.host with environment.apiUrl
            args[0] = url.replace(environment.host, environment.apiUrl);
        }
        // @ts-ignore
        return target.apply(thisArg, args);
    }
});

// Override XMLHttpRequest
(function(open) {
    XMLHttpRequest.prototype.open = function(method: string, url: string | URL, ...args: any[]) {
        console.log('XMLHttpRequest', method, url);
        return open.apply(this, [method, url, ...args] as any);
    };
})(XMLHttpRequest.prototype.open);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

<app-breadcrumb [breadcrumbs]="breadcrumbs$ | async"/>

<h1 class="page-title">Media Discovery Options</h1>

<mat-divider class="page-title-divider"></mat-divider>

<app-wrapper-table matSort #sort="matSort" [tableSort]="sort"
                   [dataToDisplay]="dataToDisplay$ | async"
                   [hideIfEmpty]="true"
>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
            <a [routerLink]="'/advertiser/' + (advertiser$ | async)?.aid + '/reports-and-tools/media-discovery-tool/review/' + (element | asType : MediaDiscoveryQuote).id">
                {{ (element | asType : MediaDiscoveryQuote).name }}
            </a>

        </td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </th>
        <td mat-cell *matCellDef="let element">
            {{ (element | asType : MediaDiscoveryQuote).createdDateMillis | date: 'MM-dd-yyyy' }}
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['name', 'createdDate']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['name', 'createdDate']"></tr>
</app-wrapper-table>

import { DataSource } from '@angular/cdk/collections';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';

export class TableDataSource extends DataSource<any> {
    private dataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public loading$: Observable<boolean> = this.loadingSubject.asObservable();

    private dataLengthSubject: ReplaySubject<number> = new ReplaySubject<number>(1);
    public dataLength$: Observable<number> = this.dataLengthSubject.asObservable();

    constructor(initialData: any[] | null = null) {
        super();
        if (initialData) {
            this.updateData(initialData);
            this.updateDataLength(initialData.length);
        }
    }

    connect(): Observable<any[]> {
        return this.dataSubject;
    }

    disconnect() {
        this.dataSubject.complete();
    }

    updateData(data: any[]) {
        this.loadingSubject.next(false);
        this.dataSubject.next(data);
    }

    updateDataLength(dataLength: number) {
        this.dataLengthSubject.next(dataLength);
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'number',
    standalone: true
})
export class NumberPipe implements PipeTransform {
    transform(value?: number | null): unknown {
        if (value === null || value === undefined) {
            return '';
        }
        return value.toLocaleString('en-US');
    }
}
